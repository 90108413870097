<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid class="pb-0">
          <v-form ref="form">
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" xs="12" sm="5">
                <v-select
                  v-model="form.type"
                  :items="selTypes"
                  label="유형"
                  dense
                  menu-props="auto"
                  class="mr-3 pt-3"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="2">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      class="ml-1 pt-3"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>보수항목의 추가,변경,삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" xs="12" sm="5">
                <v-select
                  v-model="form.status"
                  :items="selStatus"
                  label="상태"
                  dense
                  menu-props="auto"
                  class="mr-3 pt-3"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-menu
                  ref="dateSelector"
                  v-model="dateSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model.trim="form.date"
                      label="일자"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                      class="mr-3"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    locale="ko"
                    @change="saveDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field
                  ref="numAccount"
                  v-model.trim="form.account"
                  v-validate="'required|max:15|is_not:0'"
                  :error-messages="errors.collect('account')"
                  data-vv-name="account"
                  required
                  label="*금액"
                  maxlength="15"
                  suffix="원"
                  @keypress="preventComma"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-textarea
                  ref="refComment"
                  v-model.trim="form.comment"
                  :rules="[rules.txtCounter]"
                  label="내용"
                  outlined
                  no-resize
                  rows="5"
                  counter="100"
                  @update:error="etcError"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import isNumber from '@/filters/isNumber'

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      type: '', // 유형
      status: '확정', // 상태
      date: '', // 일자
      account: '0', // 금액
      comment: '' // 내용(남긴글)
    },
    // 구분: 보수약정 유형 수정 -> 카테고리 관리의 '보수유형/수금항목' 사용으로 변경됨
    selTypes: [],
    // selTypes: [
    //   { text: '수임료', value: '수임료' },
    //   { text: '착수보수', value: '착수보수' },
    //   { text: '성공보수', value: '성공보수' },
    //   { text: '타임차지', value: '타임차지' },
    //   { text: '비용청구', value: '비용청구' }
    // ],
    selStatus: [
      { text: '확정', value: '확정' },
      { text: '예정', value: '예정' }
    ],
    // 구분:
    dictionary: {
      messages: ko.messages,
      attributes: {
        account: '금액'
      }
    },
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 100 || '100자 이하의 내용을 입력해 주세요'
    },
    dateSelector: false,
    chkValidate: true // 폼 유효성 - 내용이 100자 이상이면 false
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    // !! 보수유형/수금항목은 관리자 > 카테고리에서 패칭한다.
    this.setCates('11').then(({ cates }) => {
      if (cates && cates.length > 0) {
        this.selTypes = cates.map(c => c.gubun1)
        this.form.type = this.selTypes[0] // 초기값 지정
      } else {
        this.selTypes = []
      }
    })
  },

  watch: {
    // 'form.account': { // 금액 자동감지
    //   handler: function (val, oldVal) {
    //     if (val.length > 0) { // 이게 없으면 무한루프 걸릴 수 있다.
    //       if (isNumber(val)) {
    //         this.form.account = oldVal
    //         this.$refs.numAccount.lazyValue = oldVal
    //       }
    //     }
    //   }
    // }
    // !![2021.3.16] 수정
    'form.account': {
      handler: function (val, oldVal) {
        if (!val) {
          this.form.account = '0'
          this.$refs.numAccount.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.form.account = val.replace(/(^0+)/, '')
          }
          this.form.account = numberFormatR(this.form.account)
          this.form.account = numberFormat(this.form.account)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.form.account)) {
            this.form.account = oldVal
            this.$refs.numAccount.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    isNumber, // 숫자만 인지 체크하는 필터
    numberFormat,
    numberFormatR,
    preventComma,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화 함수
    async initDialog () {
      // form validation 초기화
      this.$validator.reset()

      this.form.type = '수임료' // 유형
      this.form.status = '확정' // 상태
      this.form.date = this.$moment().format('YYYY-MM-DD') // 일자(오늘)
      this.form.account = '0' // 금액
      this.form.comment = ''

      this.index = -1 // 수정용 인덱스 초기화
    },
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index

          this.form.type = obj.type
          this.form.status = obj.status
          this.form.date = obj.date.substr(0, 10)
          this.form.account = obj.account
          this.form.comment = obj.comment
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록/수정 버튼
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 중요: textarea 의 글자수가 200 이하로 조정되면 여기서 체크해서 처리한다.
        if (this.rules.txtCounter(this.$refs.refComment.value) === true) {
          this.chkValidate = true
        }
        if (!this.chkValidate) throw new Error('저장할 수 없습니다. 내용 항목의 글자수를 줄여주세요.')

        // 한번더 - 금액에 대해 숫자만 남긴다
        this.form.account = this.form.account.replace(/[^0-9]/g, '')

        // 일자 처리 - 시분초를 붙여준다.(00:00:01 로 해서 일정에서 밑에 위치할 것이다.)
        // this.form.date = `${this.form.date.substr(0, 10)} 00:00:01`
        // console.log(this.form)

        // 폼값을 복사하고 초기화 한다. 복사한 값은 리턴하는 값이 된다
        const returnObj = Object.assign({}, this.form)
        await this.initDialog()
        // this.resolve(true)
        this.resolve(returnObj)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.$nextTick(() => {
        this.initDialog()
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      })
    },
    // 삭제
    async remove () {
      try {
        if (this.index < 0) throw new Error(`잘못된 요청입니다.`)
        const returnIndex = this.index // 넘기는 인덱스를 복사

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          await this.initDialog() // 초기화
          this.resolve(returnIndex)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    saveDate (date) {
      this.$refs.dateSelector.save(date)
    },
    // 텍스트 에어리어 에러 처리 이벤트 핸들러
    etcError () {
      this.chkValidate = false
    },
    // 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
