<template>
  <v-container fluid class="pa-0 ma-0">

    <!-- 상단 툴바 -->
    <v-toolbar dense flat>
      <v-btn text small @click="goList">
        <v-icon small>mdi-view-list</v-icon>
        목록
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        text
        small
        color="success"
        @click="editArticle">
        <v-icon small>mdi-pencil</v-icon>
        수정
      </v-btn>
      <v-btn
        text
        small
        color="error"
        @click="deleteArticle">
        <v-icon small>mdi-trash-can-outline</v-icon>
        삭제
      </v-btn>
      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <!-- 컨텐츠 영역 -->
    <v-card
      tile
      :elevation="0"
    >
      <v-row
        no-gutters
      >
        <v-col v-if="!isId" cols="12">
          <v-alert
            prominent
            type="warning"
            class="ma-4 pa-10"
          >
            존재하지 않는 데이터 입니다.<br>삭제되었거나 팀이 변경되었을 수 있습니다.
          </v-alert>
        </v-col>
        <v-col v-else cols="12">
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
            >
              <div
                class="pa-2"
              >
                <v-btn
                  outlined
                  small
                  :color="article.status2 === '진행' ? 'primary' : ''"
                  class="mb-1 mr-2"
                  style="font-size: 0.825rem !important;font-weight: 600;cursor: default;"
                >
                  {{ article.status2 }}
                </v-btn>
                <v-icon
                  v-show="article.isStar"
                  small
                  class="pb-1"
                  color="amber darken-1"
                >mdi-star</v-icon>
                <v-icon
                  v-show="article.endDate && article.endType"
                  small
                  class="pb-1"
                  color="warning"
                >mdi-gavel</v-icon>
                <span class="mx-1 text-h6">
                  {{ article.courtName }} {{ article.caseNum }}{{ article.manageNum ? `/${article.manageNum}` : '' }}
                </span>
                <!-- <span
                  v-show="article.gubun4 === '전자'"
                  class="text-body-2 green--text text--darken-1"
                >
                  [{{ article.gubun4 }}]
                </span> -->
                <v-btn
                  v-show="article.gubun4 === '전자'"
                  small tile depressed class="mb-1 mx-1 px-1 white--text"
                  color="success"
                  style="font-size: 0.855rem;font-weight: 600;letter-spacing: 0.08em;cursor: default;"
                >
                  {{ article.gubun4 }}
                </v-btn>
              </div>
              <v-divider></v-divider>
            </v-col>

            <!-- 좌측 컨텐츠 시작 --->
            <v-col
              cols="12"
              md="8"
              lg="8"
              xl="8"
            >
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="8"
                  lg="8"
                >

                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col cols="2" class="ma-0 px-2 pt-2 text-end">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">사건명</span>
                    </v-col>
                    <v-col md="auto" lg="auto" class="ma-0 px-2 pt-2 text-start">
                      <span class="text-body-2 font-weight-bold">{{ article.caseName }}</span>
                      <!-- !! 판례검색 아이콘: 법원인 경우만 활성화 된다.. -->
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="article.type2 === 1"
                            v-on="on"
                            small class="ml-2 mb-1" color="primary"
                            @click.stop="goPanrey"
                          >mdi-bank-outline</v-icon>
                        </template>
                        <span>판례검색</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col cols="2" class="ma-0 px-2 pt-1 text-end">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">의뢰인</span>
                    </v-col>
                    <v-col md="auto" lg="auto" class="ma-0 px-2 pt-1 text-start">
                      <span class="text-body-2 primary--text" style="font-weight: 600;">{{ article.clName }}</span>
                      <span class="error--text" style="font-size: 0.785rem !important">{{ article.clGusok ? ' (구속) ' : '' }}</span>
                      <span class="grey--text text--darken-1 ml-2" style="font-size: 0.825rem !important">{{ article.clType }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col cols="2" class="ma-0 px-2 pt-1 text-end">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">상대방</span>
                    </v-col>
                    <v-col md="auto" lg="auto" class="ma-0 px-2 pt-1 text-start">
                      <span class="text-body-2">{{ article.opName }}</span>
                      <span class="error--text" style="font-size: 0.785rem !important">{{ article.opGusok ? ' (구속) ' : '' }}</span>
                      <span class="grey--text text--darken-1 ml-2" style="font-size: 0.825rem !important">{{ article.opType }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col cols="2" class="ma-0 px-2 pt-1 text-end">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">재판부</span>
                    </v-col>
                    <v-col md="auto" lg="auto" class="ma-0 px-2 pt-1 text-start">
                      <span class="text-body-2">
                       {{ article.jepanbu }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col cols="2" class="ma-0 px-2 pt-1 text-end">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">접수일</span>
                    </v-col>
                    <v-col md="auto" lg="auto" class="ma-0 px-2 pt-1 text-start">
                      <span class="text-body-2">
                        {{ article.receiveDate }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col cols="2" class="ma-0 px-2 pt-1 text-end">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">소가</span>
                    </v-col>
                    <v-col md="auto" lg="auto" class="ma-0 px-2 pt-1 text-start">
                      <span class="text-body-2">
                        {{ article.requestBill }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col cols="2" class="ma-0 px-2 pt-1 text-end">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">분야</span>
                    </v-col>
                    <v-col md="auto" lg="auto" class="ma-0 px-2 pt-1 text-start">
                      <span class="text-body-2">
                        {{ article.gubun1 }}{{ article.gubun2 ? ` ＞ ${article.gubun2}` : '' }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col cols="2" class="ma-0 px-2 py-1 text-end">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">심급</span>
                    </v-col>
                    <v-col md="auto" lg="auto" class="ma-0 px-2 pt-1 text-start">
                      <span class="text-body-2">
                        {{ article.gubun3 }}
                      </span>
                    </v-col>
                  </v-row>

                </v-col>
                <v-divider vertical class="ma-0 pa-0"></v-divider>

                <!-- 좌측 상단 > 우측 -->
                <v-col>
                  <v-row no-gutters class="ma-0 pa-1">
                    <v-col class="ma-0 pa-0">
                      <div class="px-1 pt-1">
                        <span class="text-body-2 grey--text text--darken-1 mr-1">담당부서</span>
                        <span :class="`${article.teamColor}`">
                          {{ article.teamName }}
                        </span>
                      </div>
                      <div class="px-1 pt-1">
                        <span class="text-body-2 grey--text text--darken-1 mr-1">등록일</span>
                        <span class="text-body-2">
                          {{ strDateFormat(article.createdAt) }}
                        </span>
                      </div>
                      <div class="px-1 pt-1 pb-3">
                        <span class="text-body-2 grey--text text--darken-1 mr-1">관리그룹</span>
                        <span class="text-body-2">
                          {{ article.mgroupStr }}
                        </span>
                      </div>
                      <v-divider class="pt-2"></v-divider>
                      <div class="px-1 pt-1">
                        <span class="text-body-2 grey--text text--darken-1 mr-1">결과</span>
                        <div class="text-body-2 error--text font-weight-medium">
                          <!-- <v-icon
                            v-show="article.endDate && article.endType"
                            small
                            color="warning"
                            class="mr-1"
                          >mdi-gavel</v-icon> -->
                          {{ strDateFormat(article.endDate) }} {{ article.endType }}
                        </div>
                      </div>
                      <div class="px-1">
                        <span class="text-body-2">
                          {{ article.endMemo }}
                        </span>
                      </div>
                      <div class="px-1">
                        <span class="text-body-2 red--text">{{ article.endResult }}</span>
                        <span class="text-body-2 green--text">{{ article.appealPlan ? ` | ${article.appealPlan}` : '' }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                >
                  <v-divider></v-divider>
                  <v-row
                    align="center"
                    justify="center"
                    no-gutters
                  >
                    <!-- 좌측 하단(진행내용 + 업무) -->
                    <v-col cols="12" class="pt-3 pb-1 px-2">
                      <v-row
                        no-gutters
                        align="center"
                        justify="center"
                      >
                        <v-col cols="6">
                          <span class="text-body-2 grey--text text--darken-1 mr-3">진행내용 + 업무</span>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                              <v-btn icon small :color="mdiPlusBoxOutlineButtonColor"
                                v-on="on"
                              >
                                <v-icon
                                  small
                                  class="pb-1"
                                >mdi-plus-box-outline</v-icon>
                              </v-btn>
                            </template>
                            <v-card
                              class="mx-auto"
                              max-width="300"
                              tile
                            >
                              <v-list
                                dense
                                flat
                              >
                                <v-list-item
                                  v-for="(item, index) in upmuMenus"
                                  :key="index"
                                  @click="upmuPop(item)"
                                  :style="item.active ? 'cursor: pointer' : 'cursor: not-allowed'"
                                >
                                  <v-list-item-action class="mx-2 pr-3">
                                    <v-icon small :color="item.active ? item.iconColor : 'blue-grey darken-2'">
                                      {{ item.active ? item.icon : 'mdi-cancel' }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content class="mr-3 pr-3">{{ item.text }}</v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item
                                  v-for="item in upmuMenus2"
                                  :key="item.text"
                                  @click="upmuPop(item)"
                                >
                                  <v-list-item-action class="mx-2 pr-3">
                                    <v-icon small :color="item.iconColor || 'blue-grey darken-2'" v-text="item.icon"></v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content class="mr-3 pr-3">{{ item.text }}</v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-menu>
                          <!-- // * [2022.10.11 추가] 진행내용 붙여넣기 팝업 -->
                          <v-btn
                            small
                            depressed
                            color="blue-grey"
                            class="ml-1 white--text"
                            @click="copyJcontentPop"
                          >
                            진행내용 붙여넣기
                            <v-icon
                              small
                              right
                              dark
                            >
                              mdi-content-paste
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col class="mb-2">
                          <v-row
                            align="center"
                            justify="end"
                            no-gutters
                          >
                            <v-spacer></v-spacer>
                            <v-select
                              v-model="upmuSelect"
                              :items="upmuSelects"
                              dense
                              filled
                              shaped
                              solo-inverted
                              item-color="warning"
                              flat
                              hide-details
                              style="max-width: 120px; height: 28px; font-size: 0.785rem !important"
                              class="my-0 mr-2 pa-0"
                              @change="changeUpmuSelect"
                            ></v-select>
                          </v-row>
                        </v-col>
                      </v-row>
                      <template v-for="(item, i) in relContents">
                        <v-row
                          :key="i"
                          no-gutters
                        >
                          <v-col cols="12" xs="12" sm="12">
                            <v-progress-linear
                              v-show="item.todayLoop === 1 || item.todayAfterDay === true"
                              class="my-2"
                              color="red lighten-2"
                              value="0"
                            ></v-progress-linear>
                            <v-card
                              elevation="0"
                              class="mt-1"
                            >
                              <v-card-text class="pa-1 text--primary">
                                <v-hover v-slot:default="{ hover }">
                                  <div class="mb-1">
                                    <div class="px-1">
                                      <v-icon
                                        v-show="item.isStar"
                                        x-small
                                        class="mr-1 pb-1"
                                        color="amber darken-1"
                                      >mdi-star</v-icon>
                                      <!-- !![2021.5.27] 종국인 경우 아이콘 -->
                                      <v-icon
                                        v-show="item.oType === 21 && item.subtype === 8"
                                        x-small
                                        color="warning"
                                        class="mr-1 pb-1"
                                      >mdi-gavel</v-icon>
                                      <span
                                        :class="item.today ? 'primary--text' : item.pastDay ? 'grey--text' : ''"
                                        style="font-size: 0.825rem !important;font-weight: 450;"
                                      >{{ strDateFormat3(item.date1) }}</span>
                                      <v-chip
                                        label outlined x-small class="ml-2 mr-1 px-1"
                                        :color="item.oType === 21 ? (iconColors[item.subtype] || 'blue-grey darken-2') : 'blue-grey darken-2'"
                                        style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                      >
                                        {{ item.gubun1 }}
                                      </v-chip>
                                      <!-- !![2021.4.26]업무인 경우 업무자 보이기 -->
                                      <span v-show="item.oType === 22 && item.subtype === 1" class="text-body-2 primary--text" style="font-weight: 600;">
                                        {{ item.str2 }}
                                      </span>
                                      {{ item.view1 }}{{ item.view2 }}
                                      <!-- !! 업무 -->
                                      <template v-if="item.oType === 22 && item.subtype === 1">
                                        <span class="primary--text">#{{ item.gubun2 }}</span>
                                      </template>
                                      <!-- !! 기일 -->
                                      <template v-else-if="item.oType === 21 && item.subtype === 1">
                                        <span class="error--text" :class="`${item.str2 ? 'ml-1' : ''}`">{{ item.str2 }}{{ item.view4 }}</span>
                                        <span v-show="item.view3" class="primary--text" :class="`${item.view3 ? 'ml-1' : ''}`">#{{ item.view3 }}</span>
                                        <!-- @:기일준비사항 -->
                                        <expand-text-content v-show="item.txt1" :txtData="{ title: '기일준비사항', content: item.txt1 }"></expand-text-content>
                                        <!-- @:의뢰인주장 -->
                                        <expand-text-content v-show="item.txt2" :txtData="{ title: '의뢰인주장', content: item.txt2 }"></expand-text-content>
                                        <!-- @:상대방주장 -->
                                        <expand-text-content v-show="item.txt3" :txtData="{ title: '상대방주장', content: item.txt3 }"></expand-text-content>
                                        <!-- @:재판부판단 -->
                                        <expand-text-content v-show="item.txt4" :txtData="{ title: '재판부판단', content: item.txt4 }"></expand-text-content>
                                      </template>
                                      <!-- !! 기한 -->
                                      <template v-else-if="item.oType === 21 && item.subtype === 2">
                                        <span class="primary--text">{{ item.str2 ? ` - ${item.str2}` : '' }}</span>
                                      </template>
                                      <!-- !! 송달 -->
                                      <template v-else-if="item.oType === 21 && item.subtype === 5">
                                        <span v-show="item.date2 || item.str2" class="ml-1 error--text">
                                          {{ strDateFormat3(item.date2) }}{{ item.str2 ? ` ${item.str2}` : '' }}
                                        </span>
                                      </template>
                                      <!-- !! [2022.9.20] 제출 -->
                                      <template v-else-if="item.oType === 21 && item.subtype === 4">
                                        <span v-show="item.str2" class="ml-1 error--text">
                                          {{ item.str2 }}
                                        </span>
                                      </template>
                                    </div>
                                    <div style="font-size: 0.785rem !important" class="grey--text text--darken-1">
                                      <span class="ml-1">{{ strDateFormat2(item.updatedAt) }} {{ item.wname }}</span>
                                      <!-- @: 수정 아이콘 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            @click="upmuPop(item)"
                                          >mdi-pencil</v-icon>
                                        </template>
                                        <span>수정</span>
                                      </v-tooltip>
                                      <!-- @: 클립보드 복사 아이콘 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            @click="doCopy(item.copyText)"
                                          >mdi-content-copy</v-icon>
                                        </template>
                                        <span>클립보드복사</span>
                                      </v-tooltip>
                                      <!-- @: 댓글 추가 아이콘 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            :data-index="i"
                                            :data-item="JSON.stringify(item)"
                                            @click="showCommentPop"
                                          >mdi-comment-text-outline</v-icon>
                                        </template>
                                        <span>댓글쓰기</span>
                                      </v-tooltip>
                                      <!-- @: 첨부파일 추가 아이콘 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            :data-index="i"
                                            :data-item="JSON.stringify(item)"
                                            @click="showFilePop"
                                          >mdi-paperclip</v-icon>
                                        </template>
                                        <span>첨부파일</span>
                                      </v-tooltip>
                                      <!-- @: 공유링크 추가 아이콘 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && item.subtype"
                                            v-on="on"
                                            small
                                            :color="mdiListEditButtonColor"
                                            class="ml-3"
                                            :data-index="i"
                                            :data-item="JSON.stringify(item)"
                                            @click="showShareLinkPop"
                                          >mdi-link-plus</v-icon>
                                        </template>
                                        <span>공유링크</span>
                                      </v-tooltip>
                                    </div>
                                  </div>
                                </v-hover>
                                <!-- @: 댓글 & 첨부파일 & 공유링크 리스트 -->
                                <div
                                  v-for="(cmtf, i) in item.subs"
                                  :key="i"
                                  class="my-1 text-body-2"
                                >
                                  <v-hover v-slot:default="{ hover }" transition="fade-transition">
                                    <div class="ma-0 pa-0">
                                      <v-icon
                                        :color="cmtf.isAnotherWrite ? 'purple' : ''"
                                        x-small
                                      >
                                        {{ cmtf.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                                      </v-icon>
                                      <v-icon v-show="cmtf.type === 36" small :color="mdiDownloadButtonColor">mdi-paperclip</v-icon>
                                      <v-icon v-show="cmtf.type === 38" small :color="mdiLinkShareIconColor">mdi-link-plus</v-icon>
                                      <!-- !! 첨부파일, 공유링크 카테고리 태그(attach file category tag) 컴포넌트 -->
                                      <attach-file-category-tag
                                        v-show="cmtf.type === 36 || cmtf.type === 38"
                                        :caption="cmtf.gubun2"
                                      ></attach-file-category-tag>
                                      {{ cmtf.str1 }}
                                      <span class="text-caption grey--text">({{ strDateFormat2(cmtf.createdAt) }} {{ cmtf.wname }})</span>
                                      <!-- 사용안함:[2021.9.1] 첨부파일 다운로드 -->
                                      <!-- <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 36"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiDownloadButtonColor"
                                            class="ml-3"
                                            @click="fileDownload(cmtf)"
                                          >mdi-cloud-download-outline</v-icon>
                                        </template>
                                        <span>다운로드</span>
                                      </v-tooltip> -->
                                      <!-- 첨부파일 바로보기 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 36"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiOpenFileButtonColor"
                                            class="ml-3"
                                            @click="fileDirectView(cmtf)"
                                          >mdi-eye-outline</v-icon>
                                        </template>
                                        <span>바로보기</span>
                                      </v-tooltip>
                                      <!-- 첨부파일 삭제 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 36"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiTrashCanOutlineButtonColor"
                                            class="ml-3"
                                            @click="deleteFile(cmtf)"
                                          >mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                      </v-tooltip>
                                      <!-- 댓글 삭제 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 37"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiTrashCanOutlineButtonColor"
                                            class="ml-3"
                                            @click="deleteComment(cmtf)"
                                          >mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                      </v-tooltip>
                                      <!-- 공유링크 바로가기 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 38"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiPlusBoxOutlineButtonColor"
                                            class="ml-3"
                                            @click="shareLinkDirectGo(cmtf)"
                                          >mdi-launch</v-icon>
                                        </template>
                                        <span>바로가기</span>
                                      </v-tooltip>
                                      <!-- 공유링크 삭제 -->
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-icon
                                            v-show="hover && cmtf.type === 38"
                                            v-on="on"
                                            small
                                            right
                                            :color="mdiTrashCanOutlineButtonColor"
                                            class="ml-3"
                                            @click="deleteShareLink(cmtf)"
                                          >mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>삭제</span>
                                      </v-tooltip>
                                    </div>
                                  </v-hover>
                                </div>
                              </v-card-text>
                            </v-card>
                            <!-- !![2021.4.19] 오늘 데이터가 1개만 있는 경우 표출됨 -->
                            <v-progress-linear
                              v-show="item.todayLoop === 1 && relContents.length === 1"
                              class="my-2"
                              color="red lighten-2"
                              value="0"
                            ></v-progress-linear>
                          </v-col>
                        </v-row>
                      </template>
                      <v-row
                        v-if="relContents.length <= 0"
                        no-gutters
                      >
                        <v-col cols="12" xs="12" sm="12">
                          <v-card
                            elevation="0"
                            class="ma-1"
                          >
                            <v-card-text class="text--center pa-3">
                              <p class="text-subtitle-2 grey--text">
                                데이터가 없습니다.
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- @: 하단 여백 -->
                <v-col cols="12" class="my-12 py-2">
                  <div></div>
                </v-col>

              </v-row>
            </v-col>

            <v-divider vertical class="ma-0 pa-0"></v-divider>

            <!-- 우측 컨텐츠 시작 --->
            <v-col>
              <v-container fluid class="ma-0 pa-0">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <!-- @: 사실관계및쟁점정리 -->
                  <v-col cols="12" class="pt-2 pb-0 px-2">
                    <span class="text-body-2 grey--text text--darken-1 mr-3">사실관계및쟁점정리</span>
                    <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                      <v-icon
                        small
                        @click="editTxt1"
                        class="pb-1"
                      >mdi-plus-box-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-3 px-2">
                    <v-card
                      class="overflow-y-auto text-body-2 grey--text text--darken-2 pa-1"
                      elevation="0"
                      max-height="150"
                      :outlined="article.txt1 ? true : false"
                    >
                      <div id="vue2editorViewer" v-html="article.txt1"></div>
                    </v-card>
                  </v-col>

                  <!-- @: 판례/법령 -->
                  <!-- <v-col cols="12" class="my-2 pt-3 py-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">판례/법령</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="dummy"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">0</span>
                      </v-col>
                    </v-row>
                  </v-col> -->

                  <!-- @: 관련사건 -->
                  <v-col cols="12" class="my-2 pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">관련사건</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="addConnectCase"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">{{ relCases.length }}</span>
                      </v-col>
                    </v-row>
                    <template v-for="(item, i) in relCases">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="mt-0 mb-3">
                          <v-hover v-slot:default="{ hover }">
                            <v-card elevation="0">
                              <v-card-text class="px-0 pt-0 pb-1 text--primary">
                                <div class="px-1">
                                  <v-icon small left class="mr-1"
                                    :color="item.rType === 1 ? 'primary' : 'orange'"
                                  >mdi-link-variant</v-icon>
                                  <span class="text-body-2 grey--text text--darken-2" style="font-size: 0.8rem;font-weight: 400;">
                                    {{ item.str2 }}
                                    <template v-if="!isMyTeam(item.rInfo.teamId)">
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <span v-on="on" class="grey--text" style="font-size: 0.855rem;font-weight: 450;">
                                            {{ item.rType === 1 ? `${item.rInfo.courtName} ` : '' }}
                                            {{ item.rType === 1 ? `${item.rInfo.caseNum}${item.rInfo.manageNum ? `/${item.rInfo.manageNum}` : ''}` : item.rInfo.manageNum }}
                                          </span>
                                        </template>
                                        <span>접근권한이 없습니다. ({{ getTeamNameByTid(item.rInfo.teamId) }} 데이터 입니다.)</span>
                                      </v-tooltip>
                                    </template>
                                    <template v-else>
                                      <span class="text--primary" style="font-size: 0.855rem;font-weight: 450;">
                                        {{ item.rType === 1 ? `${item.rInfo.courtName} ` : '' }}
                                        {{ item.rType === 1 ? `${item.rInfo.caseNum}${item.rInfo.manageNum ? `/${item.rInfo.manageNum}` : ''}` : item.rInfo.manageNum }}
                                      </span>
                                    </template>
                                  </span>
                                  <v-chip
                                    label outlined x-small class="mx-1 px-1"
                                    :color="item.rInfo.status2 === '진행' ? 'primary' : 'blue-grey darken-2'"
                                    style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                  >
                                    {{ item.rInfo.status2 }}
                                  </v-chip>
                                  <span v-show="item.rInfo.endDate && item.rInfo.endType" class="error--text" style="font-size: 0.825rem;font-weight: 400;">
                                    {{ strDateFormat3(item.rInfo.endDate) }} {{ item.rInfo.endType }}
                                  </span>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="hover"
                                        v-on="on"
                                        small
                                        :color="mdiListEditButtonColor"
                                        class="ml-3"
                                        @click.stop="popEditConnectCase(item)"
                                      >mdi-pencil</v-icon>
                                    </template>
                                    <span>수정</span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="hover && isMyTeam(item.rInfo.teamId)"
                                        v-on="on"
                                        small
                                        :color="mdiPlusBoxOutlineButtonColor"
                                        class="ml-3"
                                        @click.stop="gotoDetail(item)"
                                      >mdi-launch</v-icon>
                                    </template>
                                    <span>바로가기</span>
                                  </v-tooltip>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>

                  <!-- @: 의뢰인상세및계약 -->
                  <v-col cols="12" class="my-2 pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="8">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">의뢰인상세및계약</span>
                        <v-btn
                          icon small
                          :color="mdiPlusBoxOutlineButtonColor"
                          @click="popSetRelClient"
                        >
                          <v-icon small class="pb-1">mdi-plus-box-outline</v-icon>
                        </v-btn>
                        <v-btn
                          v-show="getMyAuth.auth1"
                          icon small
                          :color="mdiPlusBoxOutlineButtonColor"
                          @click="pdfClient"
                          :disabled="this.relContracts.length === 0"
                        >
                          <v-icon small class="pb-1">mdi-download</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col class="text-right mr-2">
                        <span class="text-body-2">{{ relClients.length }}</span>
                      </v-col>
                    </v-row>
                    <template v-for="(item, i) in relClients">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="mb-3">
                          <v-hover v-slot:default="{ hover }">
                            <v-card elevation="0">
                              <v-card-text class="pa-0 text--primary">
                                <div class="px-1">
                                  <template v-if="!isMyTeam(item.rInfo.teamId)">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <span v-on="on" class="grey--text" style="font-size: 0.825rem;font-weight: 400;">
                                          <v-icon small left class="mr-1"
                                            :color="item.rInfo.isGomun ? 'purple' : 'primary'"
                                          >
                                            {{ item.rInfo.isGomun ? 'mdi-shield-account' : 'mdi-account-circle' }}
                                          </v-icon>
                                          {{ item.rInfo.name }}
                                          <span class="text-body-2">{{ item.str1 ? `${item.str1}` : item.str2 ? `${item.str2}` : '' }}</span>
                                          <span v-show="item.str4" class="ml-1">{{ item.str4 }}</span>
                                        </span>
                                      </template>
                                      <span>접근권한이 없습니다. ({{ getTeamNameByTid(item.rInfo.teamId) }} 데이터 입니다.)</span>
                                    </v-tooltip>
                                  </template>
                                  <template v-else>
                                    <v-hover v-slot:default="{ hover }">
                                      <span>
                                        <v-icon small left class="mr-1"
                                          :color="item.rInfo.isGomun ? 'purple' : 'primary'"
                                        >
                                          {{ item.rInfo.isGomun ? 'mdi-shield-account' : 'mdi-account-circle' }}
                                        </v-icon>
                                        <span class="primary--text mr-1" style="font-size: 0.875rem !important;font-weight: 500;">{{ item.rInfo.name }}</span>
                                        <span class="text-body-2">{{ item.str1 ? `${item.str1}` : item.str2 ? `${item.str2}` : '' }}</span>
                                        <span v-show="item.str4" class="ml-1">{{ item.str4 }}</span>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on }">
                                            <v-icon
                                              v-show="item.str4"
                                              v-on="on"
                                              class="ml-1"
                                              color="primary"
                                              :style="hover ? 'font-size: 1.2rem;' : 'font-size: 0.9rem;'"
                                              @click="writeEmail(item.str4)"
                                            >
                                              {{ hover ? 'mdi-email' : 'mdi-email-outline' }}
                                            </v-icon>
                                          </template>
                                          <span>메일보내기</span>
                                        </v-tooltip>
                                      </span>
                                    </v-hover>
                                  </template>
                                  <!-- !! 아이콘 영역 -->
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="hover"
                                        v-on="on"
                                        small
                                        :color="mdiListEditButtonColor"
                                        class="ml-3"
                                        @click="popEditRelClient(item)"
                                      >mdi-pencil</v-icon>
                                    </template>
                                    <span>수정</span>
                                  </v-tooltip>
                                  <!-- 개별사건계약 등록 팝업 -->
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="hover && isMyTeam(item.rInfo.teamId) && !item.rContract.id"
                                        v-on="on"
                                        small
                                        :color="getMyAuth.auth1 ? 'indigo accent-2' : 'grey lighten-1'"
                                        class="ml-3"
                                        @click.stop="setRelContract(item.rInfo, null)"
                                      >mdi-currency-krw</v-icon>
                                    </template>
                                    <span>{{ getMyAuth.auth1 ? '개별사건계약' : '접근권한이 없습니다' }}</span>
                                  </v-tooltip>
                                  <!-- 시간보수청구 등록 팝업 -->
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="hover && isMyTeam(item.rInfo.teamId)"
                                        v-on="on"
                                        small
                                        :color="getMyAuth.auth3 ? 'teal darken-3' : 'grey lighten-1'"
                                        class="ml-3"
                                        @click.stop="setRelTimesheetWithClient(item.rInfo)"
                                      >mdi-timer-outline</v-icon>
                                    </template>
                                    <span>{{ getMyAuth.auth3 ? '시간보수청구' : '접근권한이 없습니다' }}</span>
                                  </v-tooltip>
                                  <!-- 비용청구 등록 팝업 -->
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="hover && isMyTeam(item.rInfo.teamId)"
                                        v-on="on"
                                        small
                                        :color="getMyAuth.auth2 ? 'pink darken-4' : 'grey lighten-1'"
                                        class="ml-3"
                                        @click.stop="setRelCostWithClient(item.rInfo)"
                                      >mdi-cash-multiple</v-icon>
                                    </template>
                                    <span>{{ getMyAuth.auth2 ? '비용청구' : '접근권한이 없습니다' }}</span>
                                  </v-tooltip>
                                  <!-- 바로가기 -->
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="hover && isMyTeam(item.rInfo.teamId)"
                                        v-on="on"
                                        small
                                        :color="mdiPlusBoxOutlineButtonColor"
                                        class="ml-3"
                                        @click.stop="gotoDetail(item)"
                                      >mdi-launch</v-icon>
                                    </template>
                                    <span>바로가기</span>
                                  </v-tooltip>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-hover>
                          <!-- !![2021.5.18]2번째 줄: 계약사항 -->
                          <v-hover v-slot:default="{ hover }">
                            <div
                              v-show="getMyAuth.auth1 && item.rContract.id"
                              class="pl-1 pb-1"
                              style="font-size: 0.875rem !important;font-weight: 400;"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    small left class="mr-1"
                                    color="purple darken-2"
                                  >mdi-link-lock</v-icon>
                                </template>
                                <span>계약</span>
                              </v-tooltip>
                              <v-icon x-small
                                v-if="item.rContract.isStar"
                                class="mr-1"
                                color="amber darken-1"
                              >mdi-star</v-icon>
                              <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.rContract.date1) }}</span>
                              <span v-show="item.rContract.gubun2" class="ml-2">{{ item.rContract.gubun2 }}</span>
                              <span v-show="item.rContract.str3" class="ml-2" style="font-weight: 450;">￦{{ numberFormat(item.rContract.str3) }}</span>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-show="hover && item.rContract.id"
                                    v-on="on"
                                    small
                                    :color="mdiListEditButtonColor"
                                    class="ml-3"
                                    @click="setRelContract(item.rInfo, item.rContract)"
                                  >mdi-pencil</v-icon>
                                </template>
                                <span>수정</span>
                              </v-tooltip>
                            </div>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>

                  <!-- @: 상대방상세 -->
                  <v-col cols="12" class="my-2 pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">상대방상세</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="popSetRelCounterpart"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">{{ relCounterparts.length }}</span>
                      </v-col>
                    </v-row>
                    <template v-for="(item, i) in relCounterparts">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="mb-2">
                          <v-hover v-slot:default="{ hover }">
                            <v-card elevation="0">
                              <v-card-text class="pa-0 text--primary">
                                <div class="px-1">
                                  <v-icon small left class="mr-1" color="black">mdi-account-circle</v-icon>
                                  <span class="primary--text mr-1" style="font-size: 0.875rem !important;font-weight: 500;">{{ item.rInfo.name }}</span>
                                  <span class="text-body-2">{{ item.str1 ? `${item.str1}` : item.str2 ? `${item.str2}` : '' }}</span>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="hover"
                                        v-on="on"
                                        small
                                        :color="mdiListEditButtonColor"
                                        class="ml-3"
                                        @click="popEditRelCounterpart(item)"
                                      >mdi-pencil</v-icon>
                                    </template>
                                    <span>수정</span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="hover && isMyTeam(item.rInfo.teamId)"
                                        v-on="on"
                                        small
                                        :color="mdiPlusBoxOutlineButtonColor"
                                        class="ml-3"
                                        @click.stop="gotoDetail(item)"
                                      >mdi-launch</v-icon>
                                    </template>
                                    <span>바로가기</span>
                                  </v-tooltip>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-hover>
                          <v-hover v-slot:default="{ hover }">
                            <div style="font-size: 0.855rem !important" class="pl-1 pb-1">
                              {{ item.str4 }}
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-show="item.str4"
                                    v-on="on"
                                    class="ml-1"
                                    color="primary"
                                    :style="hover ? 'font-size: 1.2rem;' : 'font-size: 0.9rem;'"
                                    @click="writeEmail(item.str4)"
                                  >
                                    {{ hover ? 'mdi-email' : 'mdi-email-outline' }}
                                  </v-icon>
                                </template>
                                <span>메일보내기</span>
                              </v-tooltip>
                            </div>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>

                  <!-- @: 타임시트 -->
                  <v-col cols="12" class="my-2 pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">타임시트</span>
                        <v-btn
                          v-show="getMyAuth.auth3"
                          icon small
                          :color="mdiPlusBoxOutlineButtonColor"
                          @click="popRelTimesheet(null)"
                        >
                          <v-icon small class="pb-1">mdi-plus-box-outline</v-icon>
                        </v-btn>
                        <v-btn
                          v-show="getMyAuth.auth3"
                          icon small
                          :color="mdiPlusBoxOutlineButtonColor"
                          @click="pdfTsList"
                          :disabled="this.relTimesheets.length === 0"
                        >
                          <v-icon small class="pb-1">mdi-download</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span
                          v-show="getMyAuth.auth3"
                          class="text-body-2"
                        >{{ tmTotal }}/￦{{ numberFormat(timesheetTotal) }}</span>
                      </v-col>
                    </v-row>
                    <v-row v-if="!getMyAuth.auth3" no-gutters>
                      <v-col cols="12" class="mb-2">
                        <v-alert text dense color="blue-grey" class="text-body-2"  icon="mdi-information">
                          접근권한이 없습니다.
                        </v-alert>
                      </v-col>
                    </v-row>
                    <template v-else v-for="(item, i) in relTimesheets">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="mb-2">
                          <v-card elevation="0">
                            <v-card-text class="px-0 pt-0 pb-1 text--primary">
                              <v-hover v-slot:default="{ hover }">
                                <div>
                                  <div class="px-1">
                                    <v-icon small
                                      v-if="item.isStar"
                                      class="mr-1 pb-1"
                                      color="amber darken-1"
                                    >mdi-star</v-icon>
                                    <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                    <!-- !![2021.4.26]업무자 -->
                                    <span v-show="item.str3" class="text-body-2 primary--text ml-1" style="font-weight: 600;">
                                      {{ item.str3 }}
                                    </span>
                                    <span class="ml-1">{{ item.view1 }}/￦{{ numberFormat(item.str2) }}</span>
                                    <v-chip
                                      v-show="item.rInfo.name"
                                      small class="ml-2 mb-1 text-body-2" style="font-weight: 500;"
                                      color="primary"
                                    >
                                      <v-icon small left>mdi-account-circle</v-icon>{{ item.rInfo.name }}
                                    </v-chip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="popRelTimesheet(item)"
                                        >mdi-pencil</v-icon>
                                      </template>
                                      <span>수정</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="doCopy(item.copyText)"
                                        >mdi-content-copy</v-icon>
                                      </template>
                                      <span>클립보드복사</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </v-hover>
                              <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                {{ item.str1 }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>

                  <!-- @: 비용 -->
                  <v-col cols="12" class="my-2 pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">비용</span>
                        <v-btn
                          v-show="getMyAuth.auth2"
                          icon small
                          :color="mdiPlusBoxOutlineButtonColor"
                          @click="popRelCost(null)"
                        >
                          <v-icon small class="pb-1">mdi-plus-box-outline</v-icon>
                        </v-btn>
                        <v-btn
                          v-show="getMyAuth.auth2"
                          icon small
                          :color="mdiPlusBoxOutlineButtonColor"
                          @click="pdfCostList"
                          :disabled="this.relCosts.length === 0"
                        >
                          <v-icon small class="pb-1">mdi-download</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span
                          v-show="getMyAuth.auth2"
                          class="text-body-2"
                        >￦{{ numberFormat(costTotal) }}</span>
                      </v-col>
                    </v-row>
                    <v-row v-if="!getMyAuth.auth2" no-gutters>
                      <v-col cols="12" class="mb-2">
                        <v-alert text dense color="blue-grey" class="text-body-2"  icon="mdi-information">
                          접근권한이 없습니다.
                        </v-alert>
                      </v-col>
                    </v-row>
                    <template v-else v-for="(item, i) in relCosts">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="mb-2">
                          <v-card elevation="0">
                            <v-card-text class="px-0 pt-0 pb-1 text--primary">
                              <v-hover v-slot:default="{ hover }">
                                <div>
                                  <div class="px-1">
                                    <v-icon small
                                      v-if="item.isStar"
                                      class="mr-1 pb-1"
                                      color="amber darken-1"
                                    >mdi-star</v-icon>
                                    <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                    <v-chip
                                      label outlined x-small class="ml-2 mb-1 px-1"
                                      color="blue-grey darken-2"
                                      style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                    >
                                      {{ item.gubun1 }}
                                    </v-chip>
                                    <span class="ml-2">{{ item.str3 }}{{ item.str3 ? `/￦${numberFormat(item.str2)}` : `￦${numberFormat(item.str2)}` }}</span>
                                    <v-chip
                                      v-show="item.rInfo.name"
                                      small class="ml-2 mb-1 text-body-2" style="font-weight: 500;"
                                      color="primary"
                                    >
                                      <v-icon small left>mdi-account-circle</v-icon>{{ item.rInfo.name }}
                                    </v-chip>
                                    <span class="ml-2 error--text">{{ item.view1 }}</span>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="popRelCost(item)"
                                        >mdi-pencil</v-icon>
                                      </template>
                                      <span>수정</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="doCopy(item.copyText)"
                                        >mdi-content-copy</v-icon>
                                      </template>
                                      <span>클립보드복사</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </v-hover>
                              <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                {{ item.str1 }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>

                  <!-- 사용안함:[2021.5.20] @: 수금 -->
                  <!-- <v-col cols="12" class="my-2 pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">수금</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="popRelSugum(null)"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col class="text-right mr-2">
                        <span class="text-body-2">{{ numberFormat(sugumTotal) }}</span>
                      </v-col>
                    </v-row>
                    <template v-for="(item, i) in relSugums">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="mb-2">
                          <v-card elevation="0">
                            <v-card-text class="px-0 pt-0 pb-1 text--primary">
                              <v-hover v-slot:default="{ hover }">
                                <div>
                                  <div class="px-1">
                                    <v-icon small
                                      v-if="item.isStar"
                                      class="mr-1 pb-1"
                                      color="amber darken-1"
                                    >mdi-star</v-icon>
                                    <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                    <v-chip
                                      label outlined x-small class="ml-2 mb-1 px-1"
                                      color="blue-grey darken-2"
                                      style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                    >
                                      {{ item.gubun1 }}
                                    </v-chip>
                                    <span v-show="item.str2" class="ml-2">￦{{ numberFormat(item.str2) }}</span>
                                    <span v-show="item.gubun2 === '미발행'" class="ml-2">{{ item.gubun2 }}</span>
                                    <span v-show="item.rInfo" class="primary--text">{{ item.rInfo ? ` - ${item.rInfo}` : '' }}</span>
                                    <span v-show="item.view1" class="ml-2 error--text">{{ item.view1 }}</span>
                                    <span v-show="item.gubun3" class="ml-2 primary--text">#{{ item.gubun3 }}</span>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="popRelSugum(item)"
                                        >mdi-pencil</v-icon>
                                      </template>
                                      <span>수정</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="doCopy(item.copyText)"
                                        >mdi-content-copy</v-icon>
                                      </template>
                                      <span>클립보드복사</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </v-hover>
                              <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                {{ item.str1 }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col> -->

                  <!-- 사용안함:[2021.5.10] @: 계약 -->
                  <!-- <v-col cols="12" class="my-2 pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">계약</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="popRelContract(null)"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">{{ numberFormat(contractTotal) }}</span>
                      </v-col>
                    </v-row>
                    <template v-for="(item, i) in relContracts">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="mb-2">
                          <v-card elevation="0">
                            <v-card-text class="px-0 pt-0 pb-1 text--primary">
                              <v-hover v-slot:default="{ hover }">
                                <div>
                                  <div class="px-1">
                                    <v-icon small
                                      v-if="item.isStar"
                                      class="mr-1 pb-1"
                                      color="amber darken-1"
                                    >mdi-star</v-icon>
                                    <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.date1) }}</span>
                                    <v-chip
                                      label outlined x-small class="ml-2 mb-1 px-1"
                                      color="blue-grey darken-2"
                                      style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                                    >
                                      {{ item.gubun1 }}
                                    </v-chip>
                                    <span v-show="item.view1" class="ml-2">{{ item.view1 }}</span>
                                    <span v-show="item.str2" class="ml-2">￦{{ numberFormat(item.str2) }}</span>
                                    <span v-show="item.rInfo" class="primary--text">{{ item.rInfo ? ` - ${item.rInfo}` : '' }}</span>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="popRelContract(item)"
                                        >mdi-pencil</v-icon>
                                      </template>
                                      <span>수정</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-show="hover"
                                          v-on="on"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="doCopy(item.copyText)"
                                        >mdi-content-copy</v-icon>
                                      </template>
                                      <span>클립보드복사</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </v-hover>
                              <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                {{ item.str1 }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col> -->

                  <!-- @: 전자결재 -->
                  <v-col cols="12" class="my-2 pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">전자결재</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="addEapproval"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">{{ relElApprovals.length }}</span>
                      </v-col>
                    </v-row>
                    <template v-for="(item, i) in relElApprovals">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" class="mb-2">
                          <v-card elevation="0">
                            <v-card-text class="px-0 pt-0 pb-1 text--primary">
                              <v-hover v-slot:default="{ hover }">
                                <div>
                                  <div class="px-1">
                                    <v-icon small
                                      v-if="item.isStar"
                                      class="mr-1 pb-1"
                                      color="amber darken-1"
                                    >mdi-star</v-icon>
                                    <span style="font-size: 0.825rem !important;font-weight: 450;">{{ strDateFormat3(item.updatedAt) }}</span>
                                    <span v-show="item.gubun1" class="ml-2 primary--text">{{ item.gubun1 }}</span>
                                    <!-- 주의: 임시저장 일때는 내가 기안한 것만 보임 -->
                                    <v-tooltip
                                      v-if="item.status === 1"
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-on="on"
                                          v-show="hover && item.isMine"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="goRelEapproval(item)"
                                        >mdi-launch</v-icon>
                                      </template>
                                      <span>바로가기</span>
                                    </v-tooltip>
                                    <!-- 주의: 임시저장이 아닐때는 남의것도 보임 -->
                                    <v-tooltip
                                      v-else
                                      bottom
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-icon
                                          v-on="on"
                                          v-show="hover"
                                          small
                                          :color="mdiListEditButtonColor"
                                          class="ml-3"
                                          @click="goRelEapproval(item)"
                                        >mdi-launch</v-icon>
                                      </template>
                                      <span>바로가기</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </v-hover>
                              <div style="font-size: 0.855rem !important" class="pl-1 grey--text text--darken-2">
                                {{ item.subject }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>

                  <!-- @: 하단 여백 -->
                  <v-col cols="12" class="my-12 py-2">
                    <div></div>
                  </v-col>

                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- 소송 수정 팝업 -->
    <suit-add-dialog ref="suitAddDialog"></suit-add-dialog>
    <!-- 사실관계및쟁점정리 등록/수정 팝업 -->
    <edit-txt1-dialog ref="editTxt1" :article="article"></edit-txt1-dialog>
    <!-- 업무 등록/수정 팝업 -->
    <rel-content-dialog ref="relContentDialog" :article="article"></rel-content-dialog>
    <!-- 진행내용 > 기일 등록/수정 팝업 -->
    <rel-jinheng-dialog ref="relJinhengDialog" :article="article"></rel-jinheng-dialog>
    <!-- 진행내용 > 기한 등록/수정 팝업 -->
    <rel-jinheng-dialog2 ref="relJinhengDialog2" :article="article"></rel-jinheng-dialog2>
    <!-- 진행내용 > 송달 등록/수정 팝업 -->
    <rel-jinheng-dialog3 ref="relJinhengDialog3" :article="article"></rel-jinheng-dialog3>
    <!-- 진행내용 > 종국 등록/수정 팝업 -->
    <rel-jinheng-dialog4 ref="relJinhengDialog4" :article="article"></rel-jinheng-dialog4>
    <!-- 진행내용 > 명령,제출,공고,접수 등록/수정 팝업 -->
    <rel-jinheng-dialog5 ref="relJinhengDialog5" :article="article"></rel-jinheng-dialog5>
    <!-- 타임시트 -->
    <rel-timesheet-dialog ref="relTimesheetDialog" :article="article"></rel-timesheet-dialog>
    <!-- 비용 -->
    <rel-cost-dialog ref="relCostDialog" :article="article"></rel-cost-dialog>
    <!-- 수금 -->
    <rel-sugum-dialog ref="relSugumDialog" :article="article"></rel-sugum-dialog>
    <!-- 계약 -->
    <rel-contract-dialog ref="relContractDialog" :article="article"></rel-contract-dialog>
    <!-- 관련사건 등록 팝업 -->
    <add-rel-case-dialog ref="addRelCaseDialog" :article="article"></add-rel-case-dialog>
    <!-- 관련사건 수정 팝업 -->
    <edit-rel-case-dialog ref="editRelCaseDialog"></edit-rel-case-dialog>
    <!-- 연계인명부(의뢰인/상대방) 등록 팝업 -->
    <rel-client-dialog ref="relClientDialog" :article="article"></rel-client-dialog>
    <!-- 연계인명부(의뢰인/상대방) 수정 팝업 -->
    <rel-client-edit-dialog ref="relClientEditDialog" :article="article"></rel-client-edit-dialog>
    <!-- 새로운 인명부 등록 창 : relClientDialog 팝업에서 사용 -->
    <rel-client-add-dialog ref="relClientAddDialog"></rel-client-add-dialog>
    <!-- 새로운 소송 등록 팝업(인명부의 소스를 그대로 사용) -->
    <add-new-case-dialog ref="addNewCaseDialog"></add-new-case-dialog>
    <!-- 새로운 자문 등록 팝업(인명부의 소스를 그대로 사용) -->
    <add-new-advice-dialog ref="addNewAdviceDialog"></add-new-advice-dialog>
    <!-- 댓글 팝업 -->
    <add-comment-pop ref="addCommentPop"></add-comment-pop>
    <!-- 첨부파일 팝업 -->
    <add-file-pop ref="addFilePop"></add-file-pop>
    <!-- 공유링크 팝업 컴포넌트 -->
    <add-share-link-pop ref="addShareLinkPop"></add-share-link-pop>
    <!-- 보수약정 팝업 : 하위인 계약 팝업에서 사용 -->
    <edit-bosu-dialog ref="editBosuDialog"></edit-bosu-dialog>
    <!-- // * [2022.10.11] 진행내용 붙여넣기 팝업 -->
    <copy-jcontent-dialog ref="copyJcontentDialog" :article="article"></copy-jcontent-dialog>

  </v-container>
</template>

<script>
// @: filters
import strDateFormat from '@/filters/strDateFormat'
import strDateFormat2 from '@/filters/strDateFormat2'
import strDateFormat3 from '@/filters/strDateFormat3'
import numberFormat from '@/filters/numberFormat'
import costStatus from '@/filters/costStatus'

// @: dialogs
import suitAddDialog from '@/components/suit/SuitAddDialog' // 소송 수정
import editTxt1Dialog from '@/components/suit/editTxt1Dialog' // 사실관계및쟁점정리 등록/수정 팝업
import relContentDialog from '@/components/suit/relContentDialog' // 업무 등록/수정 팝업
import relJinhengDialog from '@/components/suit/relJinhengDialog' // 진행내용 > 기일 등록/수정 팝업
import relJinhengDialog2 from '@/components/suit/relJinhengDialog2' // 진행내용 > 기한 등록/수정 팝업
import relJinhengDialog3 from '@/components/suit/relJinhengDialog3' // 진행내용 > 송달 등록/수정 팝업
import relJinhengDialog4 from '@/components/suit/relJinhengDialog4' // 진행내용 > 종국 등록/수정 팝업
import relJinhengDialog5 from '@/components/suit/relJinhengDialog5' // 진행내용 > 명령,제출,공고,접수 등록/수정 팝업
import addRelCaseDialog from '@/components/suit/addRelCaseDialog' // 관련사건 등록 팝업
import editRelCaseDialog from '@/components/suit/editRelCaseDialog' // 관련사건 수정 팝업
import relClientDialog from '@/components/suit/relClientDialog' // 연계인명부(의뢰인/상대방) 등록 팝업
import relClientEditDialog from '@/components/suit/relClientEditDialog' // 연계인명부(의뢰인/상대방) 수정 팝업
import relClientAddDialog from '@/components/suit/relClientAddDialog' // 소송/자문에서의 새로운 연계 인명부등록
import addNewCaseDialog from '@/components/client/addNewCaseDialog' // ?? 새로운 소송 등록하는 팝업
import addNewAdviceDialog from '@/components/client/addNewAdviceDialog' // ?? 새로운 자문 등록하는 팝업
import relTimesheetDialog from '@/components/suit/relTimesheetDialog' // 타임시트 팝업
import relCostDialog from '@/components/suit/relCostDialog' // 비용 팝업
import relSugumDialog from '@/components/suit/relSugumDialog' // 수금 팝업
import relContractDialog from '@/components/suit/relContractDialog' // 계약 팝업
import editBosuDialog from '@/components/suit/editBosuDialog' // 보수약정 팝업
import copyJcontentDialog from '@/components/suit/copyJcontentDialog' // * [2022.10.11]진행내용 붙여넣기 팝업

// @: popups
import addCommentPop from '@/components/suit/addCommentPop' // 댓글 팝업
import addFilePop from '@/components/suit/addFilePop' // 첨부파일 팝업
import addShareLinkPop from '@/components/suit/addShareLinkPop' // 공유링크 팝업

// @: pdfmake view
import {
  pdfMake,
  pdfViewStyle,
  menuTitle,
  rmTagReturnArray,
  viewType1,
  viewType2 } from '@/lib/pdfmakeView'

// @: pdfmake list - 2021.5.20 추가
import { pdfListStyle } from '@/lib/pdfmakeList'

// @: tag components
import expandTextContent from '@/components/suit/expandTextContent'
import attachFileCategoryTag from '@/components/tags/attachFileCategoryTag' // 첨부파일,공유링크 카테고리 태그

// !![2021.4.30]lwc vuex 모듈 불러오기
import { createNamespacedHelpers } from 'vuex'

// !! lwc vuex 모듈
const _Lwc_ = createNamespacedHelpers('lwc')

// !! 현재 dir
const currDir = 'case'

export default {
  components: {
    suitAddDialog,
    editTxt1Dialog,
    relContentDialog,
    relJinhengDialog,
    relJinhengDialog2,
    relJinhengDialog3,
    relJinhengDialog4,
    relJinhengDialog5,
    addCommentPop,
    addFilePop,
    addShareLinkPop,
    addRelCaseDialog,
    editRelCaseDialog,
    relClientDialog,
    relClientEditDialog,
    relClientAddDialog,
    addNewCaseDialog,
    addNewAdviceDialog,
    relTimesheetDialog,
    relCostDialog,
    relSugumDialog,
    relContractDialog,
    editBosuDialog,
    copyJcontentDialog,
    attachFileCategoryTag,
    expandTextContent
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    mdiListEditButtonColor: 'indigo accent-2',
    mdiTrashCanOutlineButtonColor: 'warning darken-1',
    mdiUploadButtonColor: 'primary lighten-1',
    mdiDownloadButtonColor: 'success lighten-1',
    mdiLinkShareIconColor: 'cyan darken-2',
    mdiOpenFileButtonColor: 'orange lighten-1',
    // 구분: 게시물 데이터
    articleId: 0,
    article: {},
    // 구분: 진행내용 등록 메뉴
    // *[2022.9.21] 내역 추가
    upmuMenus: [
      { text: '기일', type: 21, subtype: 1, add: true, icon: 'mdi-file-document-edit-outline', iconColor: 'primary', active: true },
      { text: '기한', type: 21, subtype: 2, add: true, icon: 'mdi-file-document-edit-outline', iconColor: 'red darken-1', active: true },
      { text: '명령', type: 21, subtype: 3, add: true, icon: 'mdi-file-document-edit-outline', iconColor: 'green darken-2', active: true },
      { text: '제출', type: 21, subtype: 4, add: true, icon: 'mdi-file-document-edit-outline', iconColor: 'brown darken-1', active: true },
      { text: '송달', type: 21, subtype: 5, add: true, icon: 'mdi-file-document-edit-outline', iconColor: 'orange darken-3 accent-4', active: true },
      { text: '공고', type: 21, subtype: 6, add: true, icon: 'mdi-file-send', iconColor: 'blue-grey darken-2', active: true },
      { text: '접수', type: 21, subtype: 7, add: true, icon: 'mdi-file-send', iconColor: 'blue-grey darken-2', active: true },
      { text: '종국', type: 21, subtype: 8, add: true, icon: 'mdi-file-send', iconColor: 'blue-grey darken-2', active: true },
      { text: '내역', type: 21, subtype: 9, add: true, icon: 'mdi-file-send', iconColor: 'blue-grey darken-2', active: true }
    ],
    // 구분: 업무 등록 메뉴
    upmuMenus2: [
      { text: '업무', type: 22, subtype: 1, add: true, icon: 'mdi-content-paste', iconColor: 'blue-grey darken-2' }
    ],
    // 구분: 진행내용/업무 셀렉트
    upmuSelect: '전체',
    upmuSelects: [
      { text: '전체', value: '전체' },
      { text: '기일', value: '기일' },
      { text: '기한', value: '기한' },
      { text: '명령', value: '명령' },
      { text: '제출', value: '제출' },
      { text: '송달', value: '송달' },
      { text: '공고', value: '공고' },
      { text: '접수', value: '접수' }, // [2022.9.21 추가]
      { text: '종국', value: '종국' }, // [2022.9.21 추가]
      { text: '내역', value: '내역' }, // [2022.9.21 추가]
      { text: '업무', value: '업무' }
    ],
    // 구분: 서브 리스트
    relContents: [], // 진행내용 + 업무 = 2122, 21, 22
    relClientCouterparts: [], // 연계의뢰인 + 연계상대방
    relClients: [], // 연계 의뢰인
    relCounterparts: [], // 연계 상대방
    relCases: [], // 관련사건
    relTimesheets: [], // 타임시트
    relSugums: [], // 수금
    relCosts: [], // 비용
    relContracts: [], // 계약
    // relPanLaws: [], // 판례/법령 참고: 버전2 로 넘어감
    relElApprovals: [], // 전자결재
    // 구분: 첨부파일
    overlay: false, // 첨부파일 다운로드 로딩시 오버레이
    // 구분: 각 상세리스트 총합 초기값
    sugumTotal: 0, // 수금총합
    costTotal: 0, // 비용총합
    contractTotal: 0, // 계약금액 총합
    timesheetTotal: 0, // 타임시트 금액 총합
    tmTotal: '0:0', // 타임시트 시간-분 총합
    ttTotal: 0,
    mmTotal: 0,
    // 구분: 상세페이지 pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      // pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 30, 25, 30, 25 ],
      content: []
    },
    // 구분: pdf 리스트 - 의뢰인상세, 타임시트, 비용 리스트
    pdfLst: {
      styles: null,
      defaultStyle: null,
      pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 20, 20, 20, 20 ],
      content: []
    },
    // 구분: 존재하는 아이디 인지 여부
    isId: true,
    // 구분: 아이콘 색상
    iconColors: {
      1: 'primary', // 기일
      2: 'red darken-1', // 기한
      3: 'green darken-2', // 명령
      4: 'brown darken-1', // 제출
      5: 'orange darken-3', // 송달
      6: 'blue-grey darken-2', // 공고
      7: 'blue-grey darken-2', // 접수
      8: 'blue-grey darken-2', // 종국
      9: 'blue-grey darken-2' // 내역 [2022.9.21 추가]
    },
    // 구분: 부모인 소송/자문에서 따로 패칭할 객체
    pInfo: {
      id: 0,
      teamId: 1,
      type1: 1,
      type2: 1,
      type3: 0,
      gubun1: '',
      gubun2: '',
      gubun3: '',
      gubun4: '',
      gubun5: '',
      courtName: '',
      caseNum: '',
      caseName: '',
      manageNum: '',
      clType: '',
      opType: '',
      clName: '',
      opName: '',
      clGusok: 0,
      opGusok: 0,
      jepanbu: '',
      requestBill: '',
      status: 1,
      status2: '',
      mgroup1: '',
      mgroup2: '',
      mgroup3: '',
      mgroup4: '',
      mgroup5: '',
      isStar: 0,
      isAuto: 0,
      receiveDate: null,
      suimDate: null,
      endDate: null,
      endType: '',
      endMemo: '',
      endResult: '',
      appealPlan: '',
      str1: '',
      str2: '',
      str3: '',
      str4: '',
      str5: '',
      wemail: '',
      wname: '',
      createdAt: null,
      updatedAt: null
    }
  }),

  watch: {
    // !!중요: /case 내부에서 라우트 변화를 감지하여 동적으로 변경해준다.
    '$route' (to, from) {
      // console.log(`watch.route`)
      // console.log(to.params)
      // !! 넘겨준 파라미터가 있는 경우. 등록시엔 -R을 제거해야 한다.
      const paramIds = to.params.id.split('-')
      if (paramIds.length > 1 && paramIds[1] === 'E') {
        // !! 수정시엔 '-E' 가 붙어서 넘어온다
        this.articleId = Number(paramIds[0])
        // 다시 해당 아이디로 리다이렉트 시킨다.
        this.$router.push(`/${currDir}/${this.articleId}`)
      } else {
        // !! 수정이 아닌 경우
        this.articleId = Number(paramIds[0])
        // 넘어온 id로 해당 게시물 정보를 가져와야 한다
        this.getArticle(this.articleId)
      }
    }
  },

  computed: {
    // 중요: lwc 모듈 게터
    ..._Lwc_.mapGetters([
      'isMyTeam',
      'getTeamNameByTid',
      'getMyAuth'
    ])
  },

  mounted () {
    // console.log(`mounted.route`)
    // console.log(this.$route.params)

    // 중요: 넘어온 id를 매칭시켜야 한다!
    // 등록시엔 '-R' 이 붙어오므로 분리해서 적용한다. 안그러면 뷰페이지에서 목록으로 보낼때마다 초기화 해버린다.
    const paramIds = this.$route.params.id.split('-')
    this.articleId = Number(paramIds[0])

    // 넘어온 id로 해당 게시물 정보를 가져와야 한다
    this.getArticle(this.articleId)
  },

  methods: {
    strDateFormat,
    strDateFormat2,
    strDateFormat3,
    numberFormat,
    costStatus,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 목록으로 - 주의: case 보내는 것 주의
    goList () {
      this.$router.push(`/${currDir}`)
    },
    // 중요: 리스트를 리프레시 한다.
    refreshList (param = '') {
      this.$router.push(`/${currDir}/${param}`)
    },
    // 구분: 팀정보 에러시 처리하는 함수
    async popTeamError (msg) {
      try {
        this.isId = false
        this.overlay = true
        // 팝업 에러창
        const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        if (await pop.open('접근권한', msg, { color: 'warning', width: 400 })) {
          this.refreshList(`LR`)
        } else {
          this.refreshList(`LR`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 게시물 패칭
    async getArticle (id) {
      try {
        if (!id) throw new Error('[ERR-0] 잘못된 인수형식 입니다.')

        const type1 = 1 // 중요: 소송은 1

        const { data } = await this.$axios.get(`lawork/${currDir}/getCase/${id}/${type1}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.article) {
          this.article = data.article

          // 중요: 2021.2.1 들어갈 수 없는 팀이면 에러를 내고 리스트로 백한다.
          if (this.article.teamError) {
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${this.article.teamName})</b>에 속한 데이터입니다.`)
          }

          // 관리그룹 문자열 만들기
          this.article.mgroupStr = [ data.article.mgroup1, data.article.mgroup2 ].filter(g => g).join(' | ')
          // !! 진행내용/업무 정렬 항목 초기화
          this.upmuSelect = '전체'
          // 중요: 진행내용(업무,댓글,첨부파일), 관계인, 연계사건, 수금, 비용, 계약, 타임시트 목록 패칭
          await this.getSubContents() // 초기엔 모든 서브 컨텐츠 패칭
          // 중요: 전자결재(5)는 따로 패칭해야 한다.
          await this.getRelEaByIdType()
          //
          // !![2021.3.19]pInfo 를 채우는 작업
          for (let key in this.pInfo) {
            // console.log(`${key}: ${this.article[key]}`)
            this.pInfo[key] = this.article[key]
          }
          this.article.pInfo = this.pInfo // 구찮으니 걍 this.article 에 넣어버리자
          //
        } else {
          // !! 이미 삭제된 데이터인 경우 처리
          await this.popTeamError(`진행할 수 없습니다!<br>삭제되었거나 담당부서가 변경된 데이터입니다.`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 수정
    async editArticle () {
      try {
        const result = await this.$refs.suitAddDialog.open('소송', { width: 700, height: 670 }, this.articleId, this.article)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상적으로 수정하는 경우 리프레시
            this.refreshList(`${result.affectedId}-E`)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async deleteArticle () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !! [2021.2.4] 삭제시 더 많은 정보를 넘길 수 있게 post 형식으로 변경함.
          const form = {
            id: this.articleId,
            oriTeamId: this.article.teamId // 상세페이지에 있는 원래의 팀아이디
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/deleteCase`, form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (data.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 삭제불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${data.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상적으로 삭제하는 경우 리프레시
            this.refreshList(`LR`)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- 관련사건 ---
    // 관련사건 등록 팝업
    async addConnectCase () {
      try {
        const result = await this.$refs.addRelCaseDialog.open('관련사건', { width: 870, height: 700 })
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상인 경우 리스트 리프레시
            await this.getSubContents(31) // 관련사건만 리프레시 하는게 속도에 유리
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 관련사건 수정/해제 팝업
    async popEditConnectCase (item) {
      try {
        const result = await this.$refs.editRelCaseDialog.open('관련사건', { width: 870, height: 400 }, item)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상인 경우 리스트 리프레시
            await this.getSubContents(31) // 관련사건만 리프레시 하는게 속도에 유리
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- 연계 의뢰인 ---
    // #1 등록
    async popSetRelClient () {
      try {
        // 제목, 옵션, subtype=[1 의뢰인 / 2 상대방], 선택한 연계의뢰인 객체
        const subtype = 1 // !! 의뢰인
        const option = { width: 600, height: 500 }
        const result = await this.$refs.relClientDialog.open('의뢰인상세', option, subtype)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상인 경우 리스트 리프레시
            await this.getSubContents(32)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // #2 연계의뢰인 해제/취소 팝업 - [2021.5.4 추가]
    async popEditRelClient (lwcommon) {
      try {
        // 제목, 옵션, subtype=[1 의뢰인 / 2 상대방], 선택한 연계의뢰인 객체
        const subtype = 1 // !! 의뢰인
        const option = { width: 600, height: 310 }
        const result = await this.$refs.relClientEditDialog.open('의뢰인상세', option, subtype, lwcommon)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else if (result.rsMsg === 'removed') {
            // 메시지가 해제로 넘어온 경우 상세페이지를 전체 리프레시한다
            await this.getSubContents()
          } else {
            // 정상인 경우 리스트 리프레시
            await this.getSubContents(32)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- 연계 상대방 ---
    // 등록
    async popSetRelCounterpart () {
      try {
        // 제목, 옵션, subtype=[1 의뢰인 / 2 상대방], 선택한 연계의뢰인 객체
        const subtype = 2 // !! 상대방
        const option = { width: 600, height: 500 }
        const result = await this.$refs.relClientDialog.open('상대방상세', option, subtype)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상인 경우 리스트 리프레시
            await this.getSubContents(32)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 수정 - [2021.5.4 추가]
    async popEditRelCounterpart (lwcommon) {
      try {
        // 제목, 옵션, subtype=[1 의뢰인 / 2 상대방], 선택한 연계의뢰인 객체
        const subtype = 2 // !! 상대방
        const option = { width: 600, height: 300 }
        const result = await this.$refs.relClientEditDialog.open('상대방상세', option, subtype, lwcommon)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${result.chInfo.info2}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상인 경우 리스트 리프레시
            await this.getSubContents(32)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- 타임시트 등록/수정 팝업 ---
    async popRelTimesheet (relTimesheet) {
      try {
        // 연계 의뢰인 배열 만들기
        let relCls = await this.getRelClientArray()
        if (relTimesheet) {
          // @: #1) 수정/삭제 모드
          const result = await this.$refs.relTimesheetDialog.open('타임시트', { width: 600 }, 1, relTimesheet, relCls)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상인 경우 타임시트 목록만 리프레시
              await this.getSubContents(26)
            }
          }
        } else {
          // @: #2) 등록 모드
          const result = await this.$refs.relTimesheetDialog.open('타임시트', { width: 600 }, -1, null, relCls)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상인 경우 타임시트 목록만 리프레시
              await this.getSubContents(26)
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택한 연계의뢰인 정보로 고정한 타임시트를 등록하는 함수
    // 주의: 위의 popRelTimesheet() 함수에서 등록부분이 동일하다. 그러나 옵션에서 fixedClient: true 로 보내는 부분이 다르다
    async setRelTimesheetWithClient (client) {
      try {
        // !![2021.7.8] 타임시트권한 체크
        if (!this.getMyAuth.auth3) throw new Error('타임시트 권한이 없습니다!')

        let relCls = []
        relCls.push({
          text: `${client.name} ${client.hp ? `${client.hp}` : client.tel ? `${client.tel}` : ''}`,
          value: client.id,
          name: client.name,
          rInfo: client
        })
        // @: #2) 등록
        const result = await this.$refs.relTimesheetDialog.open('타임시트', { width: 600, fixedClient: true }, -1, null, relCls)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상인 경우 타임시트 목록만 리프레시
            await this.getSubContents(26)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- 비용 등록/수정 팝업 ---
    async popRelCost (relCost) {
      try {
        // 연계 의뢰인 배열 만들기
        let relCls = await this.getRelClientArray()
        if (relCost) {
          // @: #1) 수정/삭제 모드
          const result = await this.$refs.relCostDialog.open('비용', { width: 600 }, 1, relCost, relCls)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상인 경우 비용 목록만 리프레시
              await this.getSubContents(23)
            }
          }
        } else {
          // @: #2) 등록 모드
          const result = await this.$refs.relCostDialog.open('비용', { width: 600 }, -1, null, relCls)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상인 경우 비용 목록만 리프레시
              await this.getSubContents(23)
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택한 연계의뢰인 정보로 고정한 비용을 등록하는 함수
    // 주의: 위의 popRelCost() 함수에서 등록부분이 동일하다. 그러나 옵션에서 fixedClient: true 로 보내는 부분이 다르다
    async setRelCostWithClient (client) {
      try {
        // !![2021.7.8] 비용권한 체크
        if (!this.getMyAuth.auth2) throw new Error('비용 권한이 없습니다!')

        let relCls = []
        relCls.push({
          text: `${client.name} ${client.hp ? `${client.hp}` : client.tel ? `${client.tel}` : ''}`,
          value: client.id,
          name: client.name,
          rInfo: client
        })
        // @: #2) 등록
        const result = await this.$refs.relCostDialog.open('비용', { width: 600, fixedClient: true }, -1, null, relCls)
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상인 경우 비용 목록만 리프레시
            await this.getSubContents(23)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함:[2021.5.24] --- 수금 등록/수정 팝업 ---
    // async popRelSugum (relSugum) {
    //   try {
    //     // 연계 의뢰인 배열 만들기
    //     let relCls = await this.getRelClientArray()
    //     if (relSugum) {
    //       // @: #1) 수정/삭제 모드
    //       const result = await this.$refs.relSugumDialog.open('수금', { width: 600 }, 1, relSugum, relCls)
    //       if (result) {
    //         if (result.isDeleted) {
    //           // 삭제된 데이터인 경우
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
    //         } else if (result.teamError) {
    //           // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
    //         } else {
    //           // 정상인 경우 타임시트 목록만 리프레시
    //           await this.getSubContents(24)
    //         }
    //       }
    //     } else {
    //       // @: #2) 등록 모드
    //       const result = await this.$refs.relSugumDialog.open('수금', { width: 600 }, -1, null, relCls)
    //       if (result) {
    //         if (result.isDeleted) {
    //           // 삭제된 데이터인 경우
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
    //         } else if (result.teamError) {
    //           // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
    //           await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
    //         } else {
    //           // 정상인 경우 타임시트 목록만 리프레시
    //           await this.getSubContents(24)
    //         }
    //       }
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: --- 계약 등록/수정 팝업 ---
    // 사용안함:[2021.5.10] 기존 계약 팝업
    // async popRelContract (relContract) {
    //   try {
    //     // 연계 의뢰인 배열 만들기
    //     let relCls = await this.getRelClientArray()
    //     if (relContract) { // 수정 모드
    //       if (await this.$refs.relContractDialog.open('개별수임계약', { width: 800 }, 1, relContract, relCls)) {
    //         await this.getSubContents(25)
    //       }
    //     } else { // 등록 모드
    //       if (await this.$refs.relContractDialog.open('개별수임계약', { width: 800 }, -1, null, relCls)) {
    //         await this.getSubContents(25)
    //       }
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // !![2021.5.10] 새로운 계약 팝업
    // 의뢰인 정보가 필수로 들어간다.
    async setRelContract (client, contract) {
      try {
        // !![2021.5.11]계약권한 체크
        if (!this.getMyAuth.auth1) throw new Error('계약 권한이 없습니다!')

        // !! 넘겨질 부가정보
        const subInfo = {
          timeSheets: [],
          costs: [],
          timeSheetSum: 0, // 타임시트 합계
          timeMinutes: '', // 타임시트 - 시간:분 합산
          costSum: 0, // 비용 합계
          pdfTitle: ''
        }

        const pType = 1 // 소송은 1

        // !!타이틀 만들기
        let title = `${this.article.caseNum}${this.article.manageNum ? `(${this.article.manageNum})` : ''}/${client.name}`
        if (contract) {
          title = `${title}${contract.gubun2 ? `/${contract.gubun2}` : ''}`
        }
        subInfo.pdfTitle = title

        // !! 타임시트 합계 구하기 - 현 사건의 타임시트 중 선택한 의뢰인이 연결된 것을 모두 합한다.
        const rts = this.relTimesheets.filter(c => (c.pType === pType && c.pId === this.article.id && c.rType === 3 && c.rId === client.id))
        let _tts = 0
        let _mms = 0
        rts.forEach(c => {
          subInfo.timeSheetSum += c.str2

          const tmp = c.str5.split(':') // 시간,분으로 나눈다.
          _tts += Number(tmp[0])
          _mms += Number(tmp[1])
        })
        subInfo.timeSheets = rts // !! 현재 의뢰인이 패칭된 것만 붙인다

        // !![2021.5.20]타임시트의 시간:분 합산 작업
        _tts = `${_tts + parseInt(_mms / 60)}`
        _mms = `${_mms % 60}`
        if (_tts.length === 1) _tts = `0${_tts}`
        if (_mms.length === 1) _mms = `0${_mms}`
        subInfo.timeMinutes = `${_tts}:${_mms}`

        // !! 비용합계 구하기 - 현 사건의 비용 중 선택한 의뢰인이 연결된 것을 모두 합한다
        const costs = this.relCosts.filter(c => (c.pType === pType && c.pId === this.article.id && c.rType === 3 && c.rId === client.id))
        costs.forEach(c => {
          subInfo.costSum += c.str2
        })
        subInfo.costs = costs // !! 현재 의뢰인이 패칭된 것만 붙인다

        if (contract) {
          //  @: #1) 수정/삭제 모드
          const result = await this.$refs.relContractDialog.open('개별사건계약', { width: 800 }, 1, contract, client, subInfo)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상인 경우 리프레시
              // 주의:계약인 25가 아닌 의뢰인 32를 리프레시 한다
              await this.getSubContents(32)
              // 중요: 계약도 다시 패칭해야 함
              await this.getSubContents(25)
            }
          }
        } else {
          // @: #2) 등록 모드
          const result = await this.$refs.relContractDialog.open('개별사건계약', { width: 800 }, -1, null, client, subInfo)
          if (result) {
            if (result.isDeleted) {
              // 삭제된 데이터인 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상인 경우 리프레시
              // 주의:계약인 25가 아닌 의뢰인 32를 리프레시 한다
              await this.getSubContents(32)
              // 중요: 계약도 다시 패칭해야 함
              await this.getSubContents(25)
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: --- util 함수 ---
    // 연계 의뢰인 정보로 넘겨줄 배열 만들기
    // 셀렉트 태그의 아이템으로 쓰일 배열 정보를 만들어 리턴한다.
    // !![2021.7.7] rInfo: item.rInfo 를 추가했다.
    async getRelClientArray () {
      try {
        let returnArray = []
        if (this.relClients) {
          this.relClients.forEach(item => {
            // !![2021.4.2] item.rInfo 는 인명부의 객체이므로 item.rInfo.name 을 사용해야 함
            returnArray.push({
              text: `${item.rInfo.name} ${item.str1 ? `${item.str1}` : item.str2 ? `${item.str2}` : ''}`,
              value: item.rId,
              name: item.rInfo.name,
              rInfo: item.rInfo
            })
          })
        }
        return returnArray
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 사실관계및쟁점정리 띄우기
    async editTxt1 () {
      try {
        const result = await this.$refs.editTxt1.open('사실관계및쟁점정리', { width: 750 })
        if (result) {
          if (result.isDeleted) {
            // 삭제된 데이터인 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (result.teamError) {
            // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 진행내용/업무 --- 등록/수정 팝업 열기
    // 주의: 수정시 진행내용/업무 type 은 2122로 변질되었다.
    //      원래 타입은 oType 에 저장되어서 온다.
    async upmuPop (item) {
      try {
        // console.log(item)
        if (item.add) { // 주의: --- 등록 --- add 변수가 있고 true 로 넘어오는 경우 등록이다
          //
          if (item.type === 21) { // 진행내용인 경우
            // 진행내용 팝업 열기
            if (item.subtype === 1) {
              // 구분: 기일 등록
              const result = await this.$refs.relJinhengDialog.open(item.text, { width: 700 }, item.subtype, -1, null)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시
                  await this.changeUpmuSelect()
                  this.refreshList(`${this.articleId}-E`)
                }
              }
            } else if (item.subtype === 2) {
              // 구분: 기한 등록
              // 중요: 송달로 부터 넘어온 데이터 > 기준일(stdDay)과 공시송달여부(isGongsiSongdal) 객체를 넘긴다.
              const songdal = {
                stdDay: (item.stdDay) ? item.stdDay : '',
                isGongsiSongdal: (item.isGongsiSongdal) ? item.isGongsiSongdal : false
              }
              const result = await this.$refs.relJinhengDialog2.open(item.text, { width: 650 }, item.subtype, -1, null, songdal)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시
                  await this.changeUpmuSelect()
                  this.refreshList(`${this.articleId}-E`)
                }
              }
            } else if (item.subtype === 5) {
              // 구분: 송달 등록
              const result = await this.$refs.relJinhengDialog3.open(item.text, { width: 650 }, item.subtype, -1, null)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시
                  await this.changeUpmuSelect()
                  this.refreshList(`${this.articleId}-E`)
                }
              }
            } else if (item.subtype === 8) {
              // 구분: 종국 등록
              // !! 종국데이터가 중복되지 않도록 체크
              const { endDate, endType, endMemo, endResult, appealPlan } = this.article
              if (endDate || endType || endMemo || endResult || appealPlan) {
                throw new Error(`종국정보는 이미 등록되었습니다.`)
              }

              const result = await this.$refs.relJinhengDialog4.open(item.text, { width: 650 }, item.subtype, -1, null)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시
                  await this.changeUpmuSelect()
                  this.refreshList(`${this.articleId}-E`)
                }
              }
            } else {
              // * 그외 - 명령,제출,공고,접수,내역 등록
              // *[2022.9.21] 내역 추가됨
              // console.log(item.subtype)
              // 접수의 경우 접수일자가 이미 등록되었다면 다시 등록할 수 없다!
              if (item.subtype === 7) {
                const { receiveDate } = this.article
                if (receiveDate) {
                  throw new Error(`접수는 이미 등록되었습니다.`)
                }
              }

              const result = await this.$refs.relJinhengDialog5.open(item.text, { width: 650 }, item.subtype, -1, null)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시
                  await this.changeUpmuSelect()
                  this.refreshList(`${this.articleId}-E`)
                }
              }
            }
          } else if (item.type === 22) { // 업무인 경우
            // 구분: 업무 등록
            const result = await this.$refs.relContentDialog.open('업무', { width: 650 }, -1, null)
            if (result) {
              if (result.isDeleted) {
                // 삭제된 데이터인 경우
                await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
              } else if (result.teamError) {
                // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
              } else {
                // 정상인 경우 리스트 리프레시
                await this.changeUpmuSelect()
                this.refreshList(`${this.articleId}-E`)
              }
            }
          }
        } else { // 주의: --- 수정 --- type 이 아니라 oType 을 써야한다
          //
          if (item.oType === 21) { // 진행내용인 경우
            // 진행내용 팝업 열기
            if (item.subtype === 1) {
              // 구분: 기일 수정/삭제
              const result = await this.$refs.relJinhengDialog.open(item.gubun1, { width: 700 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우
                  if (result.rsMsg === 'goNextCourtDay') {
                    // !! 결과가 다음기일추가인 경우 - 기일 추가창을 새로 열어야 한다
                    await this.changeUpmuSelect()
                    this.refreshList(`${this.articleId}-E`) // 리스트 리프레시 - 주의: 리스트까지 리프레시 하기위해

                    // 약간의 딜레이를 주고 기일추가창을 연다
                    setTimeout(() => { this.upmuPop(this.upmuMenus[0]) }, 500)
                  } else if (result.rsMsg === 'edited' || result.rsMsg === 'removed') { // 나머지 경우인 경우(수정성공이나 삭제의 경우)
                    await this.changeUpmuSelect()
                    this.refreshList(`${this.articleId}-E`)
                  }
                }
              }
            } else if (item.subtype === 2) {
              // 구분: 기한 수정/삭제
              // 중요: 송달에서 도달일을 넘겨서 기한추가를 하는 경우가 있어서 다른것과 다르게 맨 마지막에 파라미터가 추가되었다!
              const songdal = { stdDay: '', isGongsiSongdal: false }
              const result = await this.$refs.relJinhengDialog2.open(item.gubun1, { width: 650 }, item.subtype, 1, item, songdal)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시
                  await this.changeUpmuSelect()
                  this.refreshList(`${this.articleId}-E`)
                }
              }
            } else if (item.subtype === 5) {
              // 구분: 송달 수정/삭제
              const result = await this.$refs.relJinhengDialog3.open(item.gubun1, { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우
                  if (result.rsMsg === 'edited' || result.rsMsg === 'removed') {
                    // 도달일자가 안넘어온 경우(수정이나 삭제인 경우) - 리프레시
                    await this.changeUpmuSelect()
                    this.refreshList(`${this.articleId}-E`)
                  } else {
                    // 중요: 도달일,공시송달여부객체가 넘어온 경우 - 기한입력창을 열어야 한다!
                    // resolve.rsMsg = { stdDay, isGongsiSongdal } 객체의 형식이다.

                    // 리프레시 하고
                    await this.changeUpmuSelect()
                    this.refreshList(`${this.articleId}-E`)

                    // 중요: 기한등록창을 열면서 도달일자를 넘긴다
                    const newItem = Object.assign({}, this.upmuMenus[1]) // 기존의 기한등록 객체를 복사한다.

                    // 도달일자를 기한의 기준일(stdDay)로, 공시송달 여부를 isGongsiSongdal 로
                    newItem.stdDay = result.rsMsg.stdDay
                    newItem.isGongsiSongdal = result.rsMsg.isGongsiSongdal

                    // 약간의 딜레이를 주고 기한창을 연다
                    setTimeout(() => { this.upmuPop(newItem) }, 500)
                  }
                }
              }
            } else if (item.subtype === 8) {
              // 구분: 종국 수정/삭제
              const result = await this.$refs.relJinhengDialog4.open(item.gubun1, { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우
                  if (result.rsMsg === 'addLimitDay') { //  결과가 기한추가인 경우 - 기한 등록창을 새로 열어야 한다
                    // 리프레시 하고
                    await this.changeUpmuSelect()
                    this.refreshList(`${this.articleId}-E`)

                    // 약간의 딜레이를 주고 기한 등록창을 연다
                    setTimeout(() => { this.upmuPop(this.upmuMenus[1]) }, 500)
                  } else {
                    // 그외의 경우(수정이나 삭제) = result.rsMsg === 'edited' or 'removed'
                    await this.changeUpmuSelect()
                    this.refreshList(`${this.articleId}-E`)
                  }
                }
              }
            } else {
              // * 그외 - 명령,제출,공고,접수,내역 등록
              // *[2022.9.21] 내역 추가됨
              const result = await this.$refs.relJinhengDialog5.open(item.gubun1, { width: 650 }, item.subtype, 1, item)
              if (result) {
                if (result.isDeleted) {
                  // 부모가 이미 삭제된 데이터인 경우
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
                } else if (result.teamError) {
                  // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                  await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
                } else {
                  // 정상인 경우 리스트 리프레시 => 삭제인 경우도 자동으로 리프레시된다.
                  await this.changeUpmuSelect()
                  this.refreshList(`${this.articleId}-E`)
                }
              }
            }
          } else if (item.oType === 22) { // 업무인 경우
            // 구분: 업무 수정/삭제.
            const result = await this.$refs.relContentDialog.open('업무', { width: 650 }, 1, item)
            if (result) {
              if (result.isDeleted) {
                // 부모가 이미 삭제된 데이터인 경우
                await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
              } else if (result.teamError) {
                // 부모의 팀오류 처리 - 상세페이지의 원래팀과 실시간팀이 서로 다른 경우 수정불가
                await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
              } else {
                // 정상인 경우 리스트 리프레시 => 삭제인 경우도 자동으로 리프레시된다.
                await this.changeUpmuSelect()
                this.refreshList(`${this.articleId}-E`)
              }
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 넘어온 타입과 리스트로 리스트 정리 작업 패칭
    async setSubContentList ({ type, list }) {
      try {
        // 합계금액 초기화
        if (type === 24) this.sugumTotal = 0 // 수금
        if (type === 23) this.costTotal = 0 // 비용
        if (type === 25) this.contractTotal = 0 // 계약
        if (type === 26) {
          this.timesheetTotal = 0 // 타임시트 금액 총합
          this.tmTotal = '0:0' // 타임시트 시-분 총합
          this.ttTotal = 0
          this.mmTotal = 0
        }

        // 중요: 소송의 사건번호/관리번호 정보
        const caseNumInfo = `${this.article.caseNum}${this.article.manageNum ? `/${this.article.manageNum}` : ''}`

        if (list.length > 0) {
          //
          // !![2021.4.19] 오늘 표기를 위한 플래그변수 선언
          let todayLoop = 0 // 1인 경우 오늘의 첫번째
          let todayAfterDay = false // 오늘 바로 다음날인지 여부
          //
          for (let item of list) {
            if (type === 2122) {
              // !! 전체 = 진행내용 + 업무 => relContents
              item.view1 = ''
              item.view2 = ''
              item.view3 = ''
              item.view4 = '' // 기일의 종국확인에만 사용
              let view1Items = []
              let view2Items = []
              let view3Items = []

              item.tm = item.date1.substr(11, 5) // 시-분 '11:30'

              // !![2021.4.19] 진행내용, 업무가 오늘인 경우
              item.today = false
              item.todayLoop = 0
              item.todayAfterDay = false // 오늘 바로 다음날인지 여부
              item.pastDay = false // !![2021.4.20] 과거의 날인지 여부
              if (item.date1.substr(0, 10) === this.$moment().format('YYYY-MM-DD')) {
                item.today = true
                item.todayLoop = ++todayLoop
              } else {
                if (!item.today && todayLoop === 0) {
                  // console.log('오늘이전')
                } else if (!item.today && todayLoop > 0) {
                  // 오늘이 지난 첫번째 아이템을 매칭해야 한다!
                  // console.log('오늘이 지남')
                  if (!todayAfterDay) {
                    item.todayAfterDay = true
                    todayAfterDay = true
                  }
                }
              }

              // !![2021.4.20] 오늘 이전인지 여부
              // if (this.$moment().diff(this.$moment(item.date1), 'days') > 0) { // 오늘 이전임
              // !![2021.4.27] 오늘이 date1 보다 이후인지 비교. 이렇게 하면 오늘도 true 일 수 있지만 오늘은 파랗게 칠하므로 상관없다.
              if (this.$moment().isAfter(item.date1)) {
                item.pastDay = true
              }

              if (item.oType === 21) {
                // !! 진행내용인 경우
                if (item.subtype === 1) {
                  // @: 기일인 경우

                  // 1) view1 = 기일명 처리
                  item.view1 = `${item.str1}`

                  // 2) view2 = (장소 + 시간)
                  // 장소 처리
                  if (item.str3) view2Items.push(item.str3)
                  // !![2021.4.7 수정] 시간 처리
                  if (!item.noTime) { view2Items.push(item.tm) } // 시간없음이 아닌 경우

                  if (view2Items.length > 0) {
                    item.view2 = `(${view2Items.join(' ')})`
                  }

                  // 3) view3 = 예정(참석/불참....)
                  if (item.gubun2) view3Items.push(item.gubun2)
                  if (view3Items.length > 0) { item.view3 = `${view3Items.join(',')}` }

                  // 중요: 4) 종국결과가 있는데 기일에 결과가 없는 경우 '종국확인' 문자열을 띄워야 한다.
                  if (this.article.endDate && this.article.endType) {
                    if (!item.str2) {
                      item.view4 = '[종국확인]'
                    }
                  }

                  // 클립보드에 복사할 텍스트 만들기
                  item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.view1} ${item.view2}${item.gubun2 ? ` [${item.gubun2}]` : ''}${item.str2 ? ` [${item.str2}]` : ''}`
                  //
                } else if (item.subtype === 2) {
                  // @: 기한인 경우
                  // 기한명 처리
                  item.view1 = item.str1

                  // 클립보드에 복사할 텍스트 만들기
                  item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.str1}${item.str2 ? ` - ${item.str2}` : ''}`
                  //
                } else if (item.subtype === 5) {
                  // @: 송달인 경우
                  // 발송내용
                  item.view1 = item.str1

                  // 발송결과 - 클립보드용
                  let resultStr = ''
                  if (item.date2) {
                    resultStr = ` - ${this.$moment(item.date2).format('YYYY.MM.DD')}`
                  }
                  if (item.str2) {
                    resultStr += ` ${item.str2}`
                  }
                  // 클립보드에 복사할 텍스트 만들기
                  item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.str1}${resultStr}`
                  //
                } else if (item.subtype === 8) {
                  // @: 종국인 경우
                  // 종국결과 + 상세결과
                  item.view1 = `${item.str1}${item.str2 ? ` - ${item.str2}` : ''}`

                  // 결과평가|향후계획
                  if (item.str3) {
                    view2Items.push(item.str3)
                  }
                  if (item.str4) {
                    view2Items.push(item.str4)
                  }
                  if (view2Items.length > 0) {
                    item.view2 = ` [${view2Items.join('|')}]`
                  }

                  // 클립보드에 복사할 텍스트 만들기
                  item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.view1}${item.view2}`
                  //
                } else {
                  // @: 그외 .. 명령(3), 제출(4), 공고(6), 접수(7), 내역(9) 인 경우
                  // 1) view1
                  item.view1 = `${item.str1}`

                  // 클립보드에 복사할 텍스트 만들기
                  item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.str1}`
                  //
                }
                //
              } else if (item.oType === 22) {
                // !! 업무인 경우
                if (item.subtype !== 0) { // 등록같은 자동입력이 아닌 경우
                  // 장소 처리
                  if (item.str3) view1Items.push(item.str3)
                  // !![2021.4.7 수정] 시간 처리
                  if (!item.noTime) { view1Items.push(item.tm) } // 시간없음이 아닌 경우
                  // view1 = (장소 + 시간)
                  if (view1Items.length > 0) {
                    item.view1 = `(${view1Items.join(' ')})`
                  }
                  // view2 = 내용 처리
                  item.view2 = item.str1 ? ` - ${item.str1} ` : ''
                } else {
                  // 등록같은 자동입력인 경우
                  item.view1 = '사건등록'
                }

                // @: 클립보드에 복사할 텍스트 만들기
                item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.view1} ${item.str1 ? `- ${item.str1}` : ''} [${item.gubun2}]`
                //
              }
              //
            } else if (type === 24) {
              // !! 수금 - relSugums
              // 사용안함:[2021.5.20] 단독 섹션에서 제외
              // @: 총합
              // item.str2 = item.str2 ? Number(item.str2) : 0
              // this.sugumTotal += item.str2
              // // @: 수금지체 처리
              // item.view1 = ''
              // if (item.status === 1 || item.status === 3) { // 청구,연기인 경우
              //   if (this.$moment().diff(item.date1, 'days') > 0) { // 오늘을 기준으로 뺐을 때 0보다 크면 지난 것이다.
              //     item.view1 = '수금지체'
              //   }
              // }

              // // !![2021.4.21] rInfo 를 JSON.parse() 할 때 SyntaxError 에러가 나면 그냥 rInfo 문자열을 사용하고
              // // 제대로 객체가 나오면 객체의 이름만 사용하는 코드
              // try {
              //   const prInfo = JSON.parse(item.rInfo)
              //   if (typeof prInfo === 'object') { // 제대로 파싱되는 경우
              //     item.rInfo = prInfo.name // 파싱된 이름을 사용
              //   }
              // } catch (e) {
              //   // 구문에러가 나면 아무것도 안함 - 그냥 rInfo 문자열 사용
              //   if (e instanceof SyntaxError) {
              //     // console.log('구문에러')
              //   } else {
              //     throw new Error(e)
              //   }
              // }

              // // @: 클립보드에 복사할 텍스트 만들기
              // let tmpTxt = (item.gubun2 === '미발행') ? '(미발행)' : `(${strDateFormat3(item.date2)} ${item.gubun2} 발행)`
              // item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] ${numberFormat(item.str2)}원 ${tmpTxt} ${item.str1 ? `- ${item.str1}` : ''} [${item.gubun3}] ${item.view1 ? `[${item.view1}]` : ''}`

              //
            } else if (type === 23) {
              // !! 비용 - relCosts
              // @: 총합
              item.str2 = item.str2 ? Number(item.str2) : 0
              this.costTotal += item.str2

              // [2021.4.21] rInfo 를 JSON.parse() 할 때 SyntaxError 에러가 나면 그냥 rInfo 문자열을 사용하고
              try {
                // [2021.7.7] 객체를 모두 사용하는 것으로 수정
                item.rInfo = JSON.parse(item.rInfo)
              } catch (e) {
                // 구문에러가 나면 아무것도 안함 - 그냥 rInfo 문자열 사용
                if (e instanceof SyntaxError) {
                  // console.log('구문에러')
                } else {
                  throw new Error(e)
                }
              }

              // @: 클립보드에 복사할 텍스트 만들기
              item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.gubun2} ${numberFormat(item.str2)}원 ${item.gubun3} ${item.str1 ? `- ${item.str1}` : ''} [${costStatus(item.status)}]`

              //
            } else if (type === 25) {
              // !! 계약 - relContracts
              // 사용안함:[2021.5.14] 계약은 단독 섹션에서 제외
              // // @: 총합
              // item.str2 = item.str2 ? Number(item.str2) : 0
              // this.contractTotal += item.str2

              // // !! 약정업무량, 타임차지캡, 비용, 부가세는 따로 패칭
              // item.view1 = ''
              // let v1Items = []

              // // 약정업무량이 있는 경우
              // if (item.gubun3 && item.gubun3 !== '없음' && item.str3) {
              //   v1Items.push(`업무(${item.gubun3}${item.str3})`)
              // }
              // // 타임차지캡이 있는 경우
              // if (item.status3 === 1 && item.str4) {
              //   v1Items.push(`캡(${numberFormat(item.str4)})`)
              // }
              // // 비용포함인 경우
              // if (item.status4 === 1) {
              //   v1Items.push('비용포함')
              // }
              // // 부가세포함인 경우
              // if (item.status5 === 1) {
              //   v1Items.push('부가세포함')
              // }
              // if (v1Items.length > 0) {
              //   item.view1 = v1Items.join('/')
              // }

              // // !![2021.4.21] rInfo 를 JSON.parse() 할 때 SyntaxError 에러가 나면 그냥 rInfo 문자열을 사용하고
              // // 제대로 객체가 나오면 객체의 이름만 사용하는 코드
              // try {
              //   const prInfo = JSON.parse(item.rInfo)
              //   if (typeof prInfo === 'object') { // 제대로 파싱되는 경우
              //     item.rInfo = prInfo.name // 파싱된 이름을 사용
              //   }
              // } catch (e) {
              //   // 구문에러가 나면 아무것도 안함 - 그냥 rInfo 문자열 사용
              //   if (e instanceof SyntaxError) {
              //     // console.log('구문에러')
              //   } else {
              //     throw new Error(e)
              //   }
              // }

              // // @: 클립보드에 복사할 텍스트 만들기
              // item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] ${item.gubun2 ? `(${item.gubun2}) ` : ''}${item.rInfo ? `${item.rInfo} ` : ''}${numberFormat(item.str2)}원 ${item.status4 === 1 ? '[비용포함]' : '[비용별도]'}${item.str1 ? ` - ${item.str1}` : ''} [${item.status ? '유효' : '해지'}]`
              //
            } else if (type === 26) {
              // !! 타임시트 - relTimesheets
              // @: 금액총합
              item.str2 = item.str2 ? Number(item.str2) : 0
              this.timesheetTotal += item.str2

              // !![2021.5.28]시-분 합산작업
              const tmTmp = item.str5.split(':')
              this.ttTotal += Number(tmTmp[0])
              this.mmTotal += Number(tmTmp[1])

              // [2021.4.21] rInfo 를 JSON.parse() 할 때 SyntaxError 에러가 나면 그냥 rInfo 문자열을 사용하고
              try {
                // [2021.7.7] 객체를 모두 사용하는 것으로 수정
                item.rInfo = JSON.parse(item.rInfo)
              } catch (e) {
                // 구문에러가 나면 아무것도 안함 - 그냥 rInfo 문자열 사용
                if (e instanceof SyntaxError) {
                  // console.log('구문에러')
                } else {
                  throw new Error(e)
                }
              }

              // @: 시간:분 처리
              item.view1 = item.str5.replace(/^0/g, '') // 맨 앞의 0 제거
              // @: 클립보드에 복사할 텍스트 만들기
              item.copyText = `${caseNumInfo} ${strDateFormat3(item.date1)} [${item.gubun1}] (${item.view1}) ${numberFormat(item.str2)}원 ${item.str1 ? `- ${item.str1}` : ''}`
              //
            } else if (type === 27) {
              // !! 판례/법령 - relPanLaws
              // console.log(item)
              //
            } else if (type === 31) {
              // !! 관련사건 - relCases
              // !![2021.3.31] 관련사건 정보문자열을 JSON 파싱해야 함
              item.rInfo = JSON.parse(item.rInfo)

              // 중요:[2021.10.13] 종국일자가 있는 경우 바르게 하기위해 포맷을 맞춰준다.
              if (item.rInfo && item.rInfo.endDate) {
                if (item.rInfo.endDate.length > 10) {
                  item.rInfo.endDate = this.$moment(item.rInfo.endDate).format('YYYY-MM-DD')
                }
              }
              //
            } else if (type === 32) {
              // !! 연계 의뢰인/연계 상대방 - relClientCouterparts => relClients, relCounterparts
              // 주의: type32 로 패칭되는 리스트는 연계 의뢰인/상대방이 같이 들어있다.

              // !![2021.3.30] 의뢰인 정보문자열을 JSON 파싱해야 함
              item.rInfo = JSON.parse(item.rInfo)

              // subtype = 1(연계의뢰인), 2(연계상대방) 이므로 subtype 으로 잘라서 배열에 넣자.
              if (item.subtype === 1) {
                // 연계의뢰인인 경우 relClients 로 넣는다
                this.relClients.push(item)
              } else {
                // 상대방인 경우 relCounterparts 로 넣는다.
                this.relCounterparts.push(item)
              }
            }
          } // end of for

          // !![2021.5.28]타임시트 시-분 총합
          if (type === 26) {
            let _mm = `${this.mmTotal % 60}`
            if (_mm.length === 1) _mm = '0' + _mm
            if (_mm === '0') _mm = '00'
            this.tmTotal = `${this.ttTotal + parseInt(this.mmTotal / 60)}:${_mm}`
          }
        }
        return list
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 서브 컨텐츠 패칭 처리 메소드
    // 중요: 진행내용/업무의 경우 타입이 2122로 넘어온다. 이 경우 oType 은 진행내용/업무의 경우 원본 타입이다
    async getSubContents (type = null, subtype = null, oType = null) {
      try {
        const columns = { caseId: this.articleId, caseType: this.article.type1, type, subtype, oType }
        const { data } = await this.$axios.post(`lawork/${currDir}/getSubContents`, columns)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        let rels = [
          { type: 2122, name: 'relContents' }, // 주의: 진행내용 + 업무 : 원래의 타입은 oType 에 담겨있다
          { type: 32, name: 'relClientCouterparts' }, // 주의: 연계 의뢰인, 연계 상대방 동시처리
          { type: 31, name: 'relCases' }, // 관련사건
          { type: 23, name: 'relCosts' }, // 비용
          { type: 24, name: 'relSugums' }, // 수금
          { type: 25, name: 'relContracts' }, // 계약
          { type: 26, name: 'relTimesheets' } // 타임시트
          // { type: 27, name: 'relPanLaws' } // 판례/법령 참고: 버전2로 넘어감
          // { type: 28, name: 'relElApprovals' } // 사용안함: 전자결재
        ]

        // 넘어온 타입이 있는 경우
        if (type) {
          if (type === 32) { // 중요: 연계 의뢰인/상대방인 경우 초기화 - 얘들은 특별처리
            this.relClients = []
            this.relCounterparts = []
          }
          const [ r ] = rels.filter(c => c.type === type)
          const list = data.list.filter(c => c.type === r.type)
          this[r.name] = await this.setSubContentList({ type: r.type, list })
        } else { // 넘어온 타입이 없다(전체)
          // 중요: 연계 의뢰인/상대방 초기화 - 얘들은 특별처리
          this.relClients = []
          this.relCounterparts = []
          //
          rels.forEach(async (r) => {
            const list = data.list.filter(c => c.type === r.type)
            this[r.name] = await this.setSubContentList({ type: r.type, list })
          })
        }

        // *[2021.2.10] 이미 접수가 등록된 경우 진행내용+업무 메뉴처리
        this.upmuMenus[6].active = !(this.article.receiveDate)

        // *[2021.2.10] 이미 종국이 등록된 경우 진행내용+업무 메뉴처리
        this.upmuMenus[7].active = !(this.article.endDate || this.article.endType || this.article.endMemo || this.article.endResult || this.article.appealPlan)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 진행내용/업무 정렬 메소드 - 이벤트나 재정렬시 쓰인다
    // 중요: 진행내용/업무의 경우 getSubContents() 함수에 타입을 2122로 넘긴다.
    //      그리고 oType 은 원래 타입을 넘긴다.
    async changeUpmuSelect () {
      try {
        if (this.upmuSelect === '전체') {
          // 전체 = 진행내역 + 업무
          await this.getSubContents(2122) // type 만 넘긴다
          //
        } else if (this.upmuSelect === '기일') {
          // 기일
          await this.getSubContents(2122, 1, 21) // type, subtype, oType 을 넘긴다
          //
        } else if (this.upmuSelect === '기한') {
          // 기한
          await this.getSubContents(2122, 2, 21)
          //
        } else if (this.upmuSelect === '명령') {
          // 명령
          await this.getSubContents(2122, 3, 21)
          //
        } else if (this.upmuSelect === '제출') {
          // 제출
          await this.getSubContents(2122, 4, 21)
          //
        } else if (this.upmuSelect === '송달') {
          // 송달
          await this.getSubContents(2122, 5, 21)
          //
        } else if (this.upmuSelect === '공고') { // *[2021.1.13 추가] 공고가 대법원에 추가됨
          // 공고
          await this.getSubContents(2122, 6, 21)
          //
        } else if (this.upmuSelect === '접수') { // *[2022.9.21 추가]
          // 접수
          await this.getSubContents(2122, 7, 21)
          //
        } else if (this.upmuSelect === '종국') { // *[2022.9.21 추가]
          // 종국
          await this.getSubContents(2122, 8, 21)
          //
        } else if (this.upmuSelect === '내역') { // *[2022.9.21 추가]
          // 내역
          await this.getSubContents(2122, 9, 21)
          //
        } else if (this.upmuSelect === '업무') {
          await this.getSubContents(2122, 1, 22)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --------------------------------------------------
    // 구분: [2022.12.1] 진행내용 붙여넣기 팝업
    async copyJcontentPop () {
      try {
        const result = await this.$refs.copyJcontentDialog.open('진행내용 붙여넣기', { width: 700 })
        console.log(result)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --------------------------------------------------
    // 구분: 클립보드에 복사하기
    // 주의: async 를 붙여선 안된다. 매뉴얼 참고
    doCopy (copyText) {
      try {
        let store = this.$store
        this.$copyText(copyText)
          .then(function (e) {
            store.commit('SB_POP', { msg: '클립보드에 복사되었습니다.', color: 'success' })
          }, function (e) {
            throw new Error(`복사하지 못했습니다. ${e}`)
          })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --------------------------------------------------
    // 구분: 연관된 전자결재 목록 패칭
    async getRelEaByIdType () {
      try {
        const rId = this.articleId
        const rType = 1 // 주의: 소송의 타입은 1
        const { data } = await this.$axios.get(`lawork/eapprove/getRelEaByIdType/${rId}/${rType}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        this.relElApprovals = data.list
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 전자결재 등록하기
    // !![2021.7.15] rInfo: JSON.stringify(this.article.pInfo) 로 넘긴다.
    async addEapproval () {
      try {
        // !! confirm 창으로 전자결재에 등록하겠냐고 물어봐야겠지?
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `전자결재를 작성 하시겠습니까?`
        if (await pop.open('확인', msg, { color: 'info', width: 400 })) {
          // !![2021.7.14] 기존 문자열은 변경시 대응이 안되므로 폐기함.
          // const rInfo = `${this.article.caseName} - ${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? ` (${this.article.manageNum})` : ''}`
          const params = {
            id: 'new', // '/eawriting/new' 로 이동시킨다.. 전자결재를 작성한다!
            rType: 1, // 소송 타입
            rId: this.article.id,
            rInfo: JSON.stringify(this.article.pInfo),
            rTid: this.article.teamId
          }
          // console.log(params)
          this.$router.push({ name: 'eawriting', params }) // !!주의: 일반적인 path 로 보내는게 아니라 이름으로 보내는것에 주의!
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연관된 전자결재로 바로가기
    async goRelEapproval (approval) {
      try {
        if (!approval) throw new Error('결재로 이동할 수 없습니다.')

        if (approval.status === 1) {
          // 임시저장인 경우 작성함으로
          this.$router.push(`/eawriting/${approval.id}`)
        } else {
          // 임시저장 이외에는 결재함으로 간다.
          this.$router.push(`/ealist/${approval.id}`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --------------------------------------------------
    // 이메일 쓰기(지메일) 바로가기
    async writeEmail (email) {
      try {
        const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${email}` // 쓰기로 바로간다.
        // const gmailUrl = `https://mail.google.com/mail/u/0/#inbox?compose=new` // 쓰기로 바로간다.
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 댓글 처리 ----
    // 댓글 팝업 열기
    async showCommentPop (evt) {
      try {
        // dataset 에 저장한 index, item 을 처리한다
        const index = evt.target.dataset.index
        const item = JSON.parse(evt.target.dataset.item) // 객체로 변환
        if (!index || !item) throw new Error('잘못된 인자 형식입니다.')

        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addCommentPop.showMenu) {
          await this.$refs.addCommentPop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const result = await this.$refs.addCommentPop.setData(item)
          if (result) {
            if (result.isDelParent) {
              // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
              this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
              // !! 경고를 뿌리고 페이지 리프레시
              // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`)
            } else if (result.isDeleted) {
              // 조부모(소송/자문/인명부 등)이 삭제된 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상적으로 등록되었으면 진행내용 리프레시
              await this.changeUpmuSelect()
            }
          }
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 댓글 삭제
    // !! [2021.2.18] id 만 받던것에서 모든 객체로 수정함
    async deleteComment (item) {
      try {
        if (!item) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !!2021.2.18 - POST 방식으로 변경하며 필요 데이터만 파라미터로 넘긴다!
          const params = {
            id: item.id,
            teamId: item.teamId,
            pId: item.pId,
            ppId: item.ppId,
            ppInfo: this.article.caseNum // item.ppInfo !![2021.3.24] 사건번호로 변경됨
          }
          const { data } = await this.$axios.post(`lawork/case/removeLwc3d`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.isDelParent) {
            // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
            this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
            // !! 경고를 뿌리고 페이지 리프레시
            // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
            await this.changeUpmuSelect()
            this.refreshList(`${this.articleId}-E`)
          } else if (data.isDeleted) {
            // 조부모(소송/자문/인명부 등)이 삭제된 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (data.teamError) {
            // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${data.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상적으로 삭제된 경우 리프레시
            await this.changeUpmuSelect()
          }
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 첨부파일 처리 ---
    // 첨부파일 팝업열기
    async showFilePop (evt) {
      try {
        // dataset 에 저장한 index, item 을 처리한다
        const index = evt.target.dataset.index
        const item = JSON.parse(evt.target.dataset.item) // 객체로 변환
        if (!index || !item) throw new Error('잘못된 인자 형식입니다.')
        // console.log(item)

        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addFilePop.showMenu) {
          await this.$refs.addFilePop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const result = await this.$refs.addFilePop.setData(item)
          if (result) {
            if (result.isDelParent) {
              // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
              this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
              // !! 경고를 뿌리고 페이지 리프레시
              // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`)
            } else if (result.isDeleted) {
              // 조부모(소송/자문/인명부 등)이 삭제된 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상적으로 등록되었으면 진행내용 리프레시
              await this.changeUpmuSelect()
            }
          }
          //
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 구분: 첨부파일 삭제
    // !! [2021.2.19] id 만 받던것에서 모든 객체로 수정함
    async deleteFile (item) {
      try {
        if (!item) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>관리자의 구글 드라이브에는 유지됩니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !!2021.2.18 - POST 방식으로 변경하며 필요 데이터만 파라미터로 넘긴다!
          const params = {
            id: item.id,
            teamId: item.teamId,
            pId: item.pId,
            ppId: item.ppId,
            ppInfo: this.article.caseNum // item.ppInfo !![2021.3.24] ppInfo 는
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/removeLwc3d`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.isDelParent) {
            // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
            this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
            // !! 경고를 뿌리고 페이지 리프레시
            // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
            await this.changeUpmuSelect()
            this.refreshList(`${this.articleId}-E`)
          } else if (data.isDeleted) {
            // 조부모(소송/자문/인명부 등)이 삭제된 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (data.teamError) {
            // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${data.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상적으로 삭제된 경우 리프레시
            await this.changeUpmuSelect()
          }
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 다운로드
    async fileDownload (file) {
      try {
        if (!file.str5) throw new Error(`유효한 파일이 아닙니다.`)
        this.overlay = true // 로딩 오버레이 on
        const webContentLink = file.str5 // 이건 다운로드

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우는 그냥 다운로드
          document.location.href = webContentLink // 다운로드 시작!
          // 2초간 로딩 딜레이
          setTimeout(() => {
            this.overlay = false // 로딩 오버레이 off
          }, 2000)
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            document.location.href = webContentLink // 다운로드 시작!
            // 2초간 로딩 딜레이
            setTimeout(() => {
              this.overlay = false // 로딩 오버레이 off
            }, 2000)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 바로보기 - 브라우저에서 바로보기
    async fileDirectView (file) {
      try {
        if (!file.str6) throw new Error(`유효한 파일이 아닙니다.`)
        const webViewLink = file.str6 // 이건 브라우저에서 바로보기

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우
          const win = window.open(webViewLink, '_blank')
          win.focus()
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            const win = window.open(webViewLink, '_blank')
            win.focus()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 공유링크 처리 ---
    // 공유링크 팝업열기
    async showShareLinkPop (evt) {
      try {
        // dataset 에 저장한 index, item 을 처리한다
        const index = evt.target.dataset.index
        const item = JSON.parse(evt.target.dataset.item) // 객체로 변환
        if (!index || !item) throw new Error('잘못된 인자 형식입니다.')

        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addShareLinkPop.showMenu) {
          await this.$refs.addShareLinkPop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const result = await this.$refs.addShareLinkPop.setData(item)
          if (result) {
            if (result.isDelParent) {
              // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
              this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
              // !! 경고를 뿌리고 페이지 리프레시
              // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
              await this.changeUpmuSelect()
              this.refreshList(`${this.articleId}-E`)
            } else if (result.isDeleted) {
              // 조부모(소송/자문/인명부 등)이 삭제된 경우
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
            } else if (result.teamError) {
              // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
              await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${result.chInfo.info1})</b>에 속한 데이터입니다.`)
            } else {
              // 정상적으로 등록되었으면 진행내용 리프레시
              await this.changeUpmuSelect()
            }
          }
          //
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 공유링크 바로가기 처리
    async shareLinkDirectGo (item) {
      try {
        // console.log(item)
        if (!item.str2) throw new Error(`유효한 공유주소가 아닙니다.`)

        // * [2022.6.7 수정] http(s):// 가 없는 경우 자동으로 붙여준다.
        let sLink = item.str2
        let httpRegex = /^(http(s)?:\/\/)/
        if (!httpRegex.test(String(item.str2).toLowerCase())) {
          sLink = 'https://' + sLink
        }

        // 다른 탭에서 링크 열기
        const link = document.createElement('a')
        link.href = sLink
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공유링크 삭제
    // !! [2021.2.19] id 만 받던것에서 모든 객체로 수정함
    async deleteShareLink (item) {
      try {
        if (!item) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !!2021.2.18 - POST 방식으로 변경하며 필요 데이터만 파라미터로 넘긴다!
          const params = {
            id: item.id,
            teamId: item.teamId,
            pId: item.pId,
            ppId: item.ppId,
            ppInfo: this.article.caseNum // item.ppInfo !![2021.3.24] ppInfo 는
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/removeLwc3d`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.isDelParent) {
            // 부모(기일, 업무 등 2depth 데이터)가 삭제된 경우
            this.$store.commit('SB_POP', { msg: '이미 삭제된 부모 데이터입니다.', color: 'error', timeout: 2500, shaped: true })
            // !! 경고를 뿌리고 페이지 리프레시
            // 주의: 접수,자문요청등이 삭제되었을 수 있기 때문에 페이지를 리프레시해야 한다.
            await this.changeUpmuSelect()
            this.refreshList(`${this.articleId}-E`)
          } else if (data.isDeleted) {
            // 조부모(소송/자문/인명부 등)이 삭제된 경우
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) 이미 삭제되었습니다.`)
          } else if (data.teamError) {
            // 팀오류가 발생한 경우 - 실시간 팀이 변경되었다.
            await this.popTeamError(`진행할 수 없습니다!<br><b>'${this.article.caseNum}'</b> 은(는) <b>타업무부서(${data.chInfo.info1})</b>에 속한 데이터입니다.`)
          } else {
            // 정상적으로 삭제된 경우 리프레시
            await this.changeUpmuSelect()
          }
          //
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 판례
    async goPanrey () {
      try {
        const searchText = this.article.caseName // 사건명
        const pUrl = `http://glaw.scourt.go.kr/wsjo/panre/sjo050.do?q=${encodeURIComponent(searchText)}`
        const link = document.createElement('a')
        link.href = pUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 상세페이지 바로가기
    // rType 에 따라 소송/자문/인명부로 보내기
    gotoDetail (item) {
      const routePath = {
        1: 'case',
        2: 'advice',
        3: 'client'
      }
      this.$router.push(`/${routePath[item.rType]}/${item.rId}`)
    },
    // 구분: pdf 상세뷰 제너레이터 - 의뢰인상세
    async pdfClient () {
      try {
        // !! 계약리스트가 비어있는 경우 아무것도 안해야 한다.
        if (this.relContracts.length === 0) return

        // !! 헤더 타이틀
        const pdfHeaderTitle = `개별사건계약 [${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? ` / ${this.article.manageNum}` : ''} ${this.article.caseName}]`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '계약번호', style: 'tableHeader', alignment: 'center' },
          { text: '착수금', style: 'tableHeader', alignment: 'center' },
          { text: '성공보수', style: 'tableHeader', alignment: 'center' },
          { text: '시간보수', style: 'tableHeader', alignment: 'center' },
          { text: '소계', style: 'tableHeader', alignment: 'center' },
          { text: '위임인', style: 'tableHeader', alignment: 'center' }
        ])

        this.relContracts.forEach(d => {
          const amts = JSON.parse(d.txt1)[0] // 착수금등 파싱
          const cInfo = JSON.parse(d.rInfo) // 의뢰인정보 파싱
          tBody.push(
            [
              { rowSpan: 2, text: `${d.isStar ? '★ ' : ''}${strDateFormat(d.date1)}` }, `${d.gubun2}`, `${numberFormat(amts.amt1)}`,
              `${numberFormat(amts.amt2)}`, `${numberFormat(amts.amt3)}`, `${numberFormat(d.str3)}`, `${cInfo.name}`
            ],
            ['', { colSpan: 6, text: `${d.str1}` }, '', '', '', '', '']
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: ['*', '*', '*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 상세뷰 제너레이터 - 타임시트
    async pdfTsList () {
      try {
        // !! 타임시트 리스트가 비어있는 경우 아무것도 안해야 한다.
        if (this.relTimesheets.length === 0) return

        // !! 헤더 타이틀
        const pdfHeaderTitle = `타임시트 [${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? ` / ${this.article.manageNum}` : ''} ${this.article.caseName}]`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '업무자', style: 'tableHeader', alignment: 'center' },
          { text: '시간당업무보수', style: 'tableHeader', alignment: 'center' },
          { text: '업무시간', style: 'tableHeader', alignment: 'center' },
          { text: '시간보수', style: 'tableHeader', alignment: 'center' },
          { text: '부담자', style: 'tableHeader', alignment: 'center' }
        ])

        this.relTimesheets.forEach(d => {
          tBody.push(
            [
              { rowSpan: 2, text: `${d.isStar ? '★ ' : ''}${strDateFormat(d.date1)}` }, `${d.str3}`, `${numberFormat(d.str4)}`,
              `${d.str5}`, `${numberFormat(d.str2)}`, `${d.rInfo.name ? d.rInfo.name : ''}`
            ],
            ['', { colSpan: 5, text: `${d.str1}` }, '', '', '', '']
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: ['*', '*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 상세뷰 제너레이터 - 비용
    async pdfCostList () {
      try {
        // !! 비용 리스트가 비어있는 경우 아무것도 안해야 한다.
        if (this.relCosts.length === 0) return

        // !! 헤더 타이틀
        const pdfHeaderTitle = `비용 [${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? ` / ${this.article.manageNum}` : ''} ${this.article.caseName}]`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '항목', style: 'tableHeader', alignment: 'center' },
          { text: '납부처', style: 'tableHeader', alignment: 'center' },
          { text: '금액', style: 'tableHeader', alignment: 'center' },
          { text: '부담자', style: 'tableHeader', alignment: 'center' }
        ])

        this.relCosts.forEach(d => {
          tBody.push(
            [
              { rowSpan: 2, text: `${d.isStar ? '★ ' : ''}${strDateFormat(d.date1)}` }, `${d.gubun1}`,
              `${d.str3}`, `${numberFormat(d.str2)}`, `${d.rInfo.name ? d.rInfo.name : ''}`
            ],
            ['', { colSpan: 4, text: `${d.str1}` }, '', '', '']
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: ['*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 상세뷰 제너레이터 - 상세뷰의 모든 리스트
    // 중요: /src/lib/pdfmakeView.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    // !![2021.5.20]수금,계약은 주석처리
    async pdfgen () {
      try {
        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: '소송 상세뷰',
          subject: '소송 상세뷰'
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfViewStyle
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // @: 헤더 타이틀
        const pdfHeaderTitle = `${this.article.isStar ? '★ ' : ''}${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? `/${this.article.manageNum}` : ''}`

        // @: 작성일
        const workDate = `작성: ${this.$moment().format('YYYY.MM.DD HH:mm')}`

        // !! #1) 기본내용
        let basicContentBody = [
          {
            stack: [
              {
                ul: [
                  `사건명: ${this.article.caseName} [${this.article.gubun4}]`,
                  `의뢰인: ${this.article.clType ? `(${this.article.clType}) ` : ''}${this.article.clName}`,
                  `상대방: ${this.article.opType ? `(${this.article.opType}) ` : ''}${this.article.opName}`,
                  `재판부: ${this.article.jepanbu}`,
                  `접수일: ${this.article.receiveDate}`,
                  `소가: ${this.article.requestBill}`,
                  `분야: ${this.article.gubun1}${this.article.gubun2 ? `|${this.article.gubun2}` : ''}`,
                  `심급: ${this.article.gubun3}`,
                  `관리그룹: ${this.article.mgroupStr}`,
                  `담당부서: ${this.article.teamName}`,
                  `등록일: ${strDateFormat(this.article.createdAt)}`,
                  `종국: ${this.article.endDate ? `${strDateFormat(this.article.endDate)} ` : ''}${this.article.endType}${this.article.endMemo ? `\n${this.article.endMemo}` : ''}${this.article.endResult ? `\n${this.article.endResult}` : ''}${this.article.appealPlan ? `|${this.article.appealPlan}` : ''}`
                ]
              }
            ],
            border: [false, false, false, false]
          }
        ]

        // !! #2) 진행내용 + 업무 :: 내부 테이블 body 에 tr > td 를 만든다. 이때 border 를 모두 없앤 스타일을 적용한다.
        let juContent = []
        this.relContents.forEach(item => {
          let text = ''
          let border = [false, false, false, false]

          text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)} [${item.gubun1}] ${item.view1}${item.view2}`
          if (item.oType === 22 && item.status) {
            // 업무
            if (item.subtype === 1) text += ` #${item.gubun2}`
          } else if (item.oType === 21 && item.subtype === 1) {
            // 기일
            text += `${item.str2 ? ` [${item.str2}]` : ''} ${item.view4}`
            text += item.view3 ? ` #${item.view3}` : ''
          } else if (item.oType === 21 && item.subtype === 2) {
            // 기한
            text += `${item.str2 ? ` - ${item.str2}` : ''}`
          } else if (item.oType === 21 && item.subtype === 5) {
            // 송달
            text += `${item.date2 ? ` [${strDateFormat3(item.date2)}` : ''}${item.str2 ? ` ${item.str2}` : ''}]`
          }

          // 댓글처리
          if (item.subs && item.subs.length > 0) {
            item.subs.forEach(cmtf => {
              text += `\n${cmtf.isAnotherWrite ? ' └▷ ' : ' ┖▶ '} ${cmtf.str1} (${strDateFormat2(cmtf.createdAt)} ${cmtf.wname})`
            })
          }

          juContent.push([ { text, border } ])
        })

        // !! #3) 사실관계 및 쟁점정리
        let rTxt1 = rmTagReturnArray(this.article.txt1)

        // !! #4) 판례/법령 -- 작업전
        // 참고: 버전 2에서 작업

        // !! #5) 관련사건
        let rcContent = []
        this.relCases.forEach(item => {
          let text = ''
          // text = `${item.str2} ${item.rInfo} ${item.str4 ? ` - ${item.str4} ` : ''}`
          // !![2021.3.30] 연계사건정보 패칭 - item.rInfo 는 이미 객체로 파싱된 상태임
          const { rInfo } = item
          if (item.rType === 1) {
            text = `${rInfo.caseName} ${rInfo.courtName} ${rInfo.caseNum}${rInfo.manageNum ? `/${rInfo.manageNum}` : ''} `
          } else {
            text = `${rInfo.caseName} ${rInfo.manageNum} `
          }
          text += `${rInfo.clName ? `${rInfo.clType ? `[${rInfo.clType}]` : ''}${rInfo.clName}` : ''}`
          text += `${rInfo.opName ? `/${rInfo.opType ? `[${rInfo.opType}]` : ''}${rInfo.opName}` : ''}`
          text += `${item.str4 ? ` - ${item.str4} ` : ''} #${rInfo.status2}`
          rcContent.push([ { text, border: [false, false, false, false] } ])
        })

        // !! #6) 의뢰인상세
        let rclContent = []
        this.relClients.forEach(item => {
          let text = ''
          text = `${item.gubun2 ? ` (${item.gubun2})` : ''}${item.rInfo.name} `
          text += `${[item.str1, item.str2, item.str4].filter(g => g).join('/')}`
          rclContent.push([ { text, border: [false, false, false, false] } ])
        })

        // !! #7) 상대방상세
        let rcpContent = []
        this.relCounterparts.forEach(item => {
          let text = ''
          text = `${item.gubun2 ? ` (${item.gubun2})` : ''}${item.rInfo.name} `
          text += `${[item.str1, item.str2, item.str4].filter(g => g).join('/')}`
          rcpContent.push([ { text, border: [false, false, false, false] } ])
        })

        // !! #8) 타임시트
        let rtContent = []
        this.relTimesheets.forEach(item => {
          let text = ''
          text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)}${item.str3 ? ` ${item.str3} ` : ''} (${item.view1}/￦${numberFormat(item.str2)})${item.rInfo.name ? ` - ${item.rInfo.name}` : ''}${item.str1 ? `\n${item.str1} ` : ''}`
          rtContent.push([ { text, border: [false, false, false, false] } ])
        })

        // !! #9) 비용
        let rcostContent = []
        this.relCosts.forEach(item => {
          let text = ''
          text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)} [${item.gubun1}]${item.str3 ? ` ${item.str3} ` : ''}￦${numberFormat(item.str2)}${item.rInfo.name ? ` - ${item.rInfo.name}` : ''}${item.view1 ? ` ${item.view1}` : ''}${item.str1 ? `\n${item.str1} ` : ''}`
          rcostContent.push([ { text, border: [false, false, false, false] } ])
        })

        // !!사용안함:[2021.5.20] #10) 수금
        // let rsugumContent = []
        // this.relSugums.forEach(item => {
        //   let text = ''
        //   text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)} [${item.gubun1}] ￦${numberFormat(item.str2)} ${item.gubun2 === '미발행' ? item.gubun2 : ''} ${item.rInfo ? ` - ${item.rInfo}` : ''}${item.view1 ? ` ${item.view1}` : ''} #${item.gubun3}${item.str1 ? `\n${item.str1} ` : ''}`
        //   rsugumContent.push([ { text, border: [false, false, false, false] } ])
        // })

        // !!사용안함:[2021.5.20] #11) 계약
        // let rcontractContent = []
        // this.relContracts.forEach(item => {
        //   // 주의: 계약에서는 rInfo 가 문자열임. 때문에 파싱해야 한다.
        //   item.rInfo = JSON.parse(item.rInfo)

        //   let text = ''
        //   text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.date1)} [${item.gubun1}] ${item.view1 ? `${item.view1}` : ''} ￦${numberFormat(item.str2)}${item.rInfo.name ? ` - ${item.rInfo.name}` : ''}${item.str1 ? `\n${item.str1} ` : ''}`
        //   rcontractContent.push([ { text, border: [false, false, false, false] } ])
        // })

        // !! #12) 전자결재
        let reaContent = []
        this.relElApprovals.forEach(item => {
          let text = ''
          text = `${item.isStar ? '★ ' : ''}${strDateFormat3(item.updatedAt)} ${item.gubun1}${item.str1 ? `\n${item.subject} ` : ''}`
          reaContent.push([ { text, border: [false, false, false, false] } ])
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: workDate, style: 'subheader' },
          menuTitle('기본내용'),
          viewType1(basicContentBody),
          menuTitle('진행내용 + 업무'),
          viewType2(juContent),
          menuTitle('사실관계및쟁점정리'),
          viewType1(rTxt1),
          // menuTitle('판례/법령'), 참고: 버전2에서
          // 판례/법령 내용...
          menuTitle('관련사건'),
          viewType2(rcContent),
          menuTitle('의뢰인상세및계약'),
          viewType2(rclContent),
          menuTitle('상대방상세'),
          viewType2(rcpContent),
          menuTitle('타임시트'),
          viewType2(rtContent),
          menuTitle('비용'),
          viewType2(rcostContent),
          // menuTitle('수금'),
          // viewType2(rsugumContent),
          // menuTitle('계약'),
          // viewType2(rcontractContent),
          menuTitle('전자결재'),
          viewType2(reaContent)
        ]

        this.pdfDoc.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
